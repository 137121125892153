import styled from 'styled-components';
import { Box, Grid, Text } from '@chakra-ui/react';

export const GridContainer = styled(Grid).attrs(() => ({
  templateColumns: 'repeat(2, 1fr)',
  gap: 6,
}))`
  width: 100%;
  height: 100%;
  word-break: break-word;
`;

export const Container = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  word-break: break-word;
`;

export const ItemText = styled(Text).attrs(() => ({
  fontSize: '14px',
}))`
  color: ${({ theme }) => theme.colors.v2.dark[200]};
  margin-right: 5px;
`;

export const CommentBox = styled.div<{
  $backgroundColor?: string;
}>`
  width: 100%;
  border-radius: 12px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  background-color: ${({ $backgroundColor, theme }) =>
    $backgroundColor || theme.colors.v2.white[200]};
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled(Box).attrs(() => ({
  as: 'form',
}))`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;
