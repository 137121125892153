/* eslint-disable no-throw-literal */
import { useKeycloak } from '@react-keycloak/web';
import { trexApi } from '../../../services/api';
import { getUUID } from '../../../services/storage';

const useHintTree = () => {
  const { keycloak } = useKeycloak();
  const UUID = getUUID();
  const prefix = 'hint-tree';

  const getAllOptions = async () => {
    try {
      const response = await trexApi.get(`${prefix}`, {
        headers: {
          'X-SetID': UUID,
          Authorization: `Bearer ${keycloak.token}`,
        },
      });

      return response;
    } catch (err) {
      throw new Error('Um erro ocorreu no useHintTree');
    }
  };

  const getOneNode = async (id: string) => {
    try {
      const response = await trexApi.get(`${prefix}/nodes/${id}`, {
        headers: {
          'X-SetID': UUID,
          Authorization: `Bearer ${keycloak.token}`,
        },
      });

      return response.data;
    } catch (err) {
      throw new Error('Um erro ocorreu no useHintTree');
    }
  };

  return {
    getAllOptions,
    getOneNode,
  };
};

export default useHintTree;
