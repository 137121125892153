import React, { useState, useEffect } from 'react';
import AutoSuggest from 'react-autosuggest';
import { trexApi } from '../../../services/api';
import Container from './styles';
import { getUUID } from '../../../services/storage';
import * as I from '../../../interfaces/components/base/autocomplete';

export default function AutocompleteModel({
  action,
  defaultValue,
  disabled,
  name,
}: I.AutoCompleteProps): React.ReactElement {
  const UUID = getUUID();
  const [list, setList] = useState<I.AutoCompleteDescription[]>([
    { id: '', description: '' },
  ]);
  const [dataValue, setDataValue] = useState('');
  const [suggestions, setSuggestions] = useState<I.AutoCompleteDescription[]>([
    { id: '', description: '' },
  ]);

  const getModel = (): Promise<React.ReactNode> =>
    new Promise((resolve, reject) => {
      trexApi
        .get(`models/${defaultValue}`, {
          headers: {
            'X-SetID': UUID,
          },
        })
        .then((result) => {
          if (result.data) {
            setDataValue(result.data.description);
            setList(result.data);
          }
        })
        .catch(() => {
          reject();
        });
    });

  const callModelsAPI = (): Promise<React.ReactNode> =>
    new Promise((resolve, reject) => {
      trexApi
        .get('models', {
          params: {
            page: 1,
            'page-size': 10,
            search: dataValue,
          },
          headers: {
            'X-SetID': UUID,
          },
        })
        .then((result) => {
          if (result.data.items) {
            setList(result.data.items);
          }
        })
        .catch(() => {
          reject();
        });
    });

  function getModelSuggestions(
    value: string
  ): Array<{ id: string; description: string }> {
    return list.filter((company) =>
      `${company.description.toLowerCase()} (ID: ${company.id.toLowerCase()})`.includes(
        value.toLowerCase().trim()
      )
    );
  }

  const callModelsAction = (suggestion: I.AutoCompleteDescription): void => {
    if (action) {
      action(suggestion.id, suggestion.description);
    }
  };

  useEffect(() => {
    callModelsAPI();
  }, [dataValue]);

  useEffect(() => {
    if (defaultValue) {
      getModel();
    }
  }, []);

  return (
    <Container>
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={(): void => setSuggestions([])}
        onSuggestionsFetchRequested={({ value }): void => {
          setDataValue(value);
          setSuggestions(getModelSuggestions(value));
        }}
        onSuggestionSelected={(_, { suggestion }): void =>
          callModelsAction(suggestion)
        }
        getSuggestionValue={(suggestion): string => suggestion.description}
        renderSuggestion={(suggestion): React.ReactElement => (
          <span>{`${suggestion.description} (ID: ${suggestion.id.substring(
            0,
            6
          )}...)`}</span>
        )}
        inputProps={{
          id: 'suggestionModelsField',
          value: dataValue,
          placeholder: 'Pesquisar',
          name: name || 'model',
          onChange: (_, { newValue }): void => {
            setDataValue(newValue);
          },
          disabled: !!disabled,
        }}
        highlightFirstSuggestion
      />
    </Container>
  );
}

AutocompleteModel.defaultProps = {
  action: null,
  name: '',
  defaultValue: '',
  disabled: false,
};
