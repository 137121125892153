export const ErrorPolicyTranslations: { [key: string]: string } = {
  ignore: 'Ignorar',
  'fail-if-needed': 'Fail-if-needed',
  fail: 'Falhar',
};

export const flowPolicyError = [
  { option: 'Ignorar', value: 'ignore' },
  { option: 'Fail-if-needed', value: 'fail-if-needed' },
  { option: 'Falhar', value: 'fail' },
];

export const retentionPolicyError = [
  { option: 'Ignorar', value: 'ignore' },
  { option: 'Falhar', value: 'fail' },
];

export const actionNodeOptions = [
  { option: 'Aprovar', value: 'approve' },
  { option: 'Rejeitar', value: 'reject' },
  { option: 'Revisar', value: 'review' },
  { option: 'Suspender', value: 'suspend' },
  { option: 'Delegar', value: 'delegar' },
];

export const blockActionNodeOptions = [
  { option: 'Aprovar', value: 'approve' },
  { option: 'Rejeitar', value: 'reject' },
  { option: 'Revisar', value: 'review' },
];

export const retentionActionNodeOptions = [
  { option: 'Reter', value: 'withhold' },
  { option: 'Não reter', value: 'dont-withhold' },
];

export const handleActionOptions = (
  isBlockd?: boolean,
  isRetentiond?: boolean
) => {
  switch (true) {
    case isBlockd:
      return blockActionNodeOptions;
    case isRetentiond:
      return retentionActionNodeOptions;
    default:
      return actionNodeOptions;
  }
};
