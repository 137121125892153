import React, { useEffect, useState } from 'react';
import { GridItem } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useOrder } from '../../../../providers/order';
import useAnalysis from '../../../../hooks/api/analysis';
import CommentsContainer from './components/commentsContainer';
import LoadingSpinner from '../../../loadingSpinner';
import CommentsForm from './components/commentsForm';

import * as S from './styles';
import * as I from '../../../../interfaces/order';
import * as I2 from './interface';

const CommentsInfo = (): React.ReactElement => {
  const { orderContext, loadOrder } = useOrder();
  const { postAnalysisComments } = useAnalysis();
  const { reset } = useForm();

  const [review, setReview] = useState<I.ReviewDetailEvents[]>();
  const [delays, setDelays] = useState<I.ReviewDetailEvents[]>();
  const [held, setHeld] = useState<I.ReviewDetailEvents[]>();
  const [loading, setLoading] = useState(true);

  const events = orderContext?.review_detail?.events;
  const comments = orderContext?.review_detail?.comments;
  const suspensionComments =
    orderContext?.suspension_detail?.events &&
    orderContext.suspension_detail.events.filter((item) => item?.message);

  useEffect(() => {
    if (events) {
      events.forEach((item) => {
        if (item.event_type === 'review') {
          if (item.action === 'finalize') {
            setReview((prevItem) => (prevItem ? [...prevItem, item] : [item]));
          }
        }
      });

      setDelays(events.filter((item) => item.action === 'delay'));
      setHeld(events.filter((item) => item.event_type === 'review-assessment'));
    }
  }, []);

  useEffect(() => {
    if (orderContext) {
      setLoading(!orderContext);
    }
  }, [orderContext]);

  const onSubmit = async (
    data: I.ReviewDetailEvents | I2.FlowCommentsFormModel
  ) => {
    const formData = data as I.ReviewDetailEvents;
    formData.event_type = 'comment';

    await postAnalysisComments({
      id: orderContext?.id,
      data: formData,
      version: orderContext?.version,
    })
      .then(() => {
        toast.success('Comentário criado com sucesso!');
        reset();
        loadOrder().catch(() =>
          toast.warn(`Não foi possível atualizar a lista de comentários`)
        );
      })
      .catch(() => {
        toast.warn(`Não foi possível criar o comentário`);
        loadOrder().catch(() =>
          toast.warn(`Não foi possível atualizar a lista de comentários`)
        );
      });
  };

  return (
    <S.GridContainer data-testid="comment-info-container">
      {loading && (
        <GridItem colSpan={2}>
          <LoadingSpinner />
        </GridItem>
      )}
      {!loading && (
        <>
          <GridItem colSpan={1}>
            <CommentsContainer
              review={review}
              delays={delays}
              comments={comments}
              held={held}
              suspensionComments={suspensionComments}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <CommentsForm onSubmit={onSubmit} />
          </GridItem>
        </>
      )}
    </S.GridContainer>
  );
};

export default CommentsInfo;
