import React, { useEffect } from 'react';

import {
  IdColumn,
  orderColumnCreatedAt,
  orderColumnDate,
  orderColumnDocument,
  orderColumnEmail,
  orderColumnName,
  orderColumnPaymentsAmount,
  orderColumnQueueTime,
  orderColumnReviewer,
  orderColumnScore,
  orderColumnSecondsUntilSLA,
  orderColumnStatus,
} from '../../components/base/v2/table/columns';
import useOrders from '../../hooks/api/orders';
import GenericListPage from '../genericList';
import { OrderModel } from '../../interfaces/order';
import useDocumentTitle from '../../hooks/documentTitle';
import { cleanFilters } from '../../components/base/v2/table/stores';

const SuspendedList: React.FC = () => {
  useDocumentTitle('Trex - Suspensos');
  const { getFilteredOrders } = useOrders();
  const { resetsearchText, resetfilterBy, resetfilters } = cleanFilters();

  useEffect(() => {
    resetsearchText();
    resetfilterBy();
    resetfilters();
  }, []);

  return (
    <GenericListPage<OrderModel>
      breadcrumb={{
        items: [{ label: 'Início', href: '/' }, { label: 'Suspensos' }],
      }}
      title="Suspensos"
      table={{
        fetch: {
          apiCall: (props) =>
            getFilteredOrders({ ...props, params: { 'only-suspended': true } }),
          title: 'pedidos suspensos',
        },
        body: {
          columns: [
            orderColumnName('/suspense'),
            IdColumn,
            orderColumnScore,
            orderColumnDocument,
            orderColumnEmail,
            orderColumnPaymentsAmount,
            orderColumnQueueTime,
            orderColumnSecondsUntilSLA,
            orderColumnCreatedAt('desc'),
            orderColumnDate,
            orderColumnStatus({ isSuspension: true }),
            orderColumnReviewer,
          ],
          searchLabel:
            'Busque por loja, ID da loja, documento, email ou ID de um pedido',
          accessURL: '/orders/info',
          searchMinWidth: '630px',
          hasQuery: true,
          isSearch: false,
          filters: { dateFromTo: true, filteredBy: true },
        },
      }}
    />
  );
};

export default SuspendedList;
