import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack, Text } from '@chakra-ui/react';
import InputText from '../../../base/v2/inputs/text';
import { documentMask } from '../../../../utils/mask';

import * as I from '../interfaces';

const InformationBaseEditBase = ({
  dataTestId,
  titleText,
  isDisabled,
}: I.InformationBaseEditBaseProps): React.ReactElement => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <Stack
      data-testid={dataTestId}
      spacing="medium"
      background="white"
      p="medium"
      borderRadius="extra-large"
      mb={3}
    >
      <Text fontWeight="bold">{titleText}</Text>
      <Stack direction="row" spacing="medium">
        <InputText
          data-testid={`${dataTestId}--name`}
          label="Parceiro"
          {...register('name')}
          formcontrol={{
            isDisabled,
            isRequired: true,
            error: errors.name,
            style: { width: '25%' },
          }}
        />

        <InputText
          data-testid={`${dataTestId}--document`}
          label="Documento"
          {...register('document')}
          onChange={(e) => setValue('document', documentMask(e.target.value))}
          formcontrol={{
            isDisabled,
            isRequired: true,
            error: errors.document,
          }}
        />

        <InputText
          data-testid={`${dataTestId}--site`}
          label="Site"
          {...register('site')}
          formcontrol={{
            isDisabled,
            isRequired: false,
            error: errors.site,
          }}
        />
      </Stack>
    </Stack>
  );
};

export default InformationBaseEditBase;
