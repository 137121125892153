import React from 'react';
import { Button, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputText from '../../../../base/v2/inputs/text';
import InputTextArea from '../../../../base/v2/inputs/textarea';
import { validationSchema } from '../utils';

import * as S from '../styles';
import * as I from '../interface';

const CommentsForm = ({
  onSubmit,
  hasTitle,
}: I.CommentsFormProps): React.ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <S.FormContainer
      onSubmit={handleSubmit(onSubmit)}
      data-testid="comment-form-container"
    >
      <Text fontWeight={700} color="#032E1B">
        Adicionar Novo Comentário
      </Text>
      {hasTitle && (
        <InputText
          label="Título"
          {...register(`title`)}
          formcontrol={{
            error: errors.title,
            style: { width: '100%', marginTop: '16px' },
            isRequired: true,
          }}
        />
      )}

      <InputTextArea
        label="Comentário"
        {...register(`message`)}
        rows={5}
        formcontrol={{
          error: errors.message,
          style: { width: '100%', margin: '16px 0' },
          isRequired: true,
        }}
      />
      <S.ButtonContainer>
        <Button type="submit" data-testid="submit-button">
          <Text>Publicar comentário</Text>
        </Button>
      </S.ButtonContainer>
    </S.FormContainer>
  );
};

export default CommentsForm;
