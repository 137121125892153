import React, { useEffect } from 'react';

import { QueueManagementModel } from '../../interfaces/queue_management';
import { cleanFilters } from '../../components/base/v2/table/stores';

import {
  AnalystsColumn,
  IdColumn,
  descriptionColumn,
  nameAndIDColumn,
  statusActiveColumn,
} from '../../components/base/v2/table/columns';
import useQueues from '../../hooks/api/queue';
import GenericListPage from '../genericList';
import useDocumentTitle from '../../hooks/documentTitle';

const QueueManagementList: React.FC = () => {
  useDocumentTitle('Trex - Gestão de filas');
  const { getQueues } = useQueues();
  const { resetsearchText, resetfilterBy, resetfilters } = cleanFilters();

  useEffect(() => {
    resetsearchText();
    resetfilterBy();
    resetfilters();
  }, []);

  return (
    <GenericListPage<QueueManagementModel>
      actionButton={{
        pushPath: '/gestao-de-filas/create',
        text: ' Criar nova fila',
      }}
      breadcrumb={{
        items: [{ label: 'Início', href: '/' }, { label: 'Gestão de filas' }],
      }}
      title="Gestão de filas"
      table={{
        fetch: {
          apiCall: getQueues,
          title: 'Gestão de filas',
        },
        body: {
          columns: [
            nameAndIDColumn('/gestao-de-filas/info'),
            IdColumn,
            descriptionColumn,
            AnalystsColumn,
            statusActiveColumn,
          ],
          hasQuery: true,
          searchLabel: 'Busque por nome, descrição, analista ou id de uma fila',
          accessURL: '/gestao-de-filas/info',
        },
      }}
    />
  );
};

export default QueueManagementList;
