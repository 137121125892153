/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-throw-literal */
import { AxiosError } from 'axios';
import { ActionsListModel } from '../../../interfaces/actions_list';
import { trexApi } from '../../../services/api';
import { getUUID } from '../../../services/storage';

import * as I from '../interfaces';
import * as I2 from './interfaces';

import { handleApiRequest } from '../utils';
import checkValid from '../../../utils/check_valid';

const useActionLists = () => {
  const UUID = getUUID();
  const prefix = 'action-lists';

  const getOneActionList = async (id: string | undefined) => {
    try {
      const response = await trexApi.get(`${prefix}/${id}`, {
        headers: {
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const getActionsList = async ({ id, query }: I.GetActionsListType) => {
    try {
      const response = await trexApi.get(`${prefix}/${id}/actions`, {
        params: {
          page: query.page + 1,
          'page-size': query.pageSize,
          'sort-by': query.orderBy?.field
            ? `${query.orderBy.field.toString()},${query.orderDirection}`
            : null,
          target: query.search ? query.search : null,
        },
        headers: {
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const getActions = async ({
    page,
    pageSize,
    orderBy,
    orderDirection,
    search,
  }: I2.ActionsAPIsQueryType) => {
    const response = await handleApiRequest(
      trexApi.get(prefix, {
        params: {
          page: page + 1,
          'page-size': pageSize,
          'sort-by': orderBy?.field
            ? `${orderBy.field.toString()},${orderDirection}`
            : null,
          search: checkValid(search),
        },
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  const getActionsItems = async ({
    page,
    pageSize,
    orderBy,
    orderDirection,
    search,
  }: I2.ActionsAPIsQueryType) => {
    const response = await handleApiRequest(
      trexApi.get(`${prefix}/actions`, {
        params: {
          page: page + 1,
          'page-size': pageSize,
          'sort-by': orderBy?.field
            ? `${orderBy.field.toString()},${orderDirection}`
            : null,
          target: checkValid(search),
        },
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  const postAction = async ({ id, data, version }: I.ActionPostType) => {
    try {
      const response = await trexApi.post(`${prefix}/${id}/actions`, data, {
        headers: {
          'If-Match': version,
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const createActionsList = async (data: ActionsListModel) => {
    try {
      const response = await trexApi.post(prefix, data, {
        headers: {
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  const deleteAction = async ({ id, id2, version }: I.GenericDoubleIdType) => {
    try {
      const response = await trexApi.delete(`${prefix}/${id}/actions/${id2}`, {
        headers: {
          'If-Match': version,
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (err: any) {
      throw new Error(err) as AxiosError;
    }
  };

  return {
    getOneActionList,
    getActionsList,
    getActions,
    getActionsItems,
    postAction,
    createActionsList,
    deleteAction,
  };
};
export default useActionLists;
