import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAtom } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';
import { AbsoluteCenter, Stack, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import validateSchema from '../../../../validations/queue_management';
import SubmenuPartners from './submenu';
import Analystis from './analystis';
import GenericFooter from '../generic/footer';
import DoubleCheckModal from '../../../base/v2/doubleCheckModal';
import LoadingSpinner from '../../../loadingSpinner';
import QueueManagementRules from './rules';
import useQueues from '../../../../hooks/api/queue';
import {
  isQueuesManagementEditingAtom,
  initialQueuesManagementDataAtom,
  queuesFiltersAtom,
} from './stores';
import { convertFormToApiFormat, defaultQueueManagement } from './utils';
import { useToastFunctions } from '../../../../utils/useToastFunction';
import {
  handleDescart,
  handleSubmitAndCloseModal,
  handleSuccessResponse,
} from '../utils';

import * as I from '../../../../interfaces/queue_management';

const QueuesManagementForm = ({
  loadInfo,
}: Readonly<I.QueueManagementFormProps>): React.ReactElement => {
  const { push } = useHistory();
  const { createQueue, updateQueue } = useQueues();
  const { showToast } = useToastFunctions();
  const [inititalData] = useAtom(initialQueuesManagementDataAtom);
  const [isEditing] = useAtom(isQueuesManagementEditingAtom);
  const [queueFilters] = useAtom(queuesFiltersAtom);
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    onOpen: onSubmitOpen,
    onClose: onSubmitClose,
    isOpen: isSubmitOpen,
  } = useDisclosure();

  const {
    onOpen: onDescartOpen,
    onClose: onDescartClose,
    isOpen: isDescartOpen,
  } = useDisclosure();

  const methods = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: defaultQueueManagement,
  });

  const submitForm = async (data: I.QueueManagementModel): Promise<boolean> => {
    const sendData = convertFormToApiFormat(queueFilters.filters, data);

    try {
      let res;

      if (inititalData.id) {
        res = await updateQueue({
          id: inititalData.id,
          data: sendData,
          version: inititalData.version,
        });
      } else {
        res = await createQueue({ data: sendData });
      }

      if (res?.data?.filters) {
        res.data.filters.forEach((filter: I.QueueManagementFiltersModel) => {
          if (filter.name === 'analysis/score') {
            filter.parameters.score *= 100;
          }
        });
      }

      handleSuccessResponse({
        res,
        isUpdate: Boolean(inititalData.id),
        loadInfo,
        methods,
        onSubmitClose,
        push,
        setIsLoaded,
        showToast,
        texts: {
          created: 'Fila publicada',
          updated: 'Fila alterada',
          path: '/gestao-de-filas/info',
        },
      });
      return true;
    } catch (error) {
      onSubmitClose();
      showToast({
        title: 'Erro ao criar fila',
        description:
          'Não foi possível criar a fila. Tente novamente mais tarde.',
        status: 'error',
      });
      return false;
    } finally {
      methods.formState.isSubmitting = false;
    }
  };

  const handleSubmit = useCallback(
    handleSubmitAndCloseModal<I.QueueManagementModel>({
      methods,
      submitFunction: submitForm,
      onSubmitClose,
    }),
    [methods, submitForm, onSubmitClose]
  );

  useEffect(() => {
    if (inititalData.id) {
      methods.reset(inititalData);
    }
    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return (
      <AbsoluteCenter>
        <LoadingSpinner />
      </AbsoluteCenter>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <Stack spacing="regular">
          <SubmenuPartners />
          <QueueManagementRules />
          <Analystis />
          <GenericFooter
            publishNewText="Publicar fila"
            publishUpdateText="Publicar alterações"
            alertText="Revise as informações antes de publicar a fila."
            onDescartOpen={onDescartOpen}
            onOpen={onSubmitOpen}
            hasID={Boolean(inititalData.id)}
            isEditing={isEditing}
          />
        </Stack>

        <DoubleCheckModal
          title="Publicar nova fila"
          description="Ao publicar as alterações, elas serão vinculadas a fila."
          isOpen={isSubmitOpen}
          onClose={onSubmitClose}
          modal={{ size: 'xl' }}
          primaryButton={{
            colorScheme: 'green',
            text: 'Publicar fila',
            action: handleSubmit,
          }}
          isLoading={methods.formState.isSubmitting}
        />

        <DoubleCheckModal
          title="Descartar alterações da fila"
          description="Ao descartar as alterações que você está fazendo agora, o formulário voltará ao estado da última atualização, eliminando todas as modificações que você fez na edição atual. Tem certeza que deseja prosseguir?"
          isOpen={isDescartOpen}
          onClose={onDescartClose}
          modal={{ size: 'xl' }}
          primaryButton={{
            colorScheme: 'green',
            text: 'Descartar alterações',
            action: () =>
              handleDescart({
                loadInfo,
                methods,
                onDescartClose,
                resetData: inititalData,
                setIsLoaded,
              }),
          }}
          isLoading={!isLoaded}
        />
      </form>
    </FormProvider>
  );
};

export default QueuesManagementForm;
