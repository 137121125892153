/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { FiLink2 } from 'react-icons/fi';
import { Query, QueryResult } from '@material-table/core';
import { AbsoluteCenter } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import useModels from '../../../../hooks/api/models';
import useClients from '../../../../hooks/api/clients';

import GenericForm from '../../../../components/base/v2/genericForm';
import LoadingSpinner from '../../../../components/loadingSpinner';
import {
  nameAndIDColumn,
  documentColumn,
  ExternalIdColumn,
  IdColumn,
} from '../../../../components/base/v2/table/columns';
import DefaultTable from '../../../../components/base/v2/table';
import { ClientModel } from '../../../../interfaces/client';
import { ModelsModel } from '../../../../interfaces/models';

const ModelsUpdateInfo = () => {
  const { keycloak } = useKeycloak();
  const { getOneModel } = useModels();
  const { getAllClients, postClientsBonds, deleteClientsModel } = useClients();
  const tableRef = useRef<any>();

  const [model, setModel] = useState<ModelsModel>();
  const [loading, setLoading] = useState(true);

  const { id } = useParams<{ id: string }>();

  const loadInfo = useCallback(async (): Promise<void> => {
    const response = await getOneModel(id);
    setModel(response.data);
    setLoading(false);
  }, [keycloak.token]);

  const reloadTable = useCallback(() => {
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange();
    }
  }, []);

  const onButtonClick = () => {
    if (tableRef.current) {
      tableRef.current.dataManager?.changeRowEditing();
      tableRef.current.setState({
        ...tableRef.current.dataManager?.getRenderState(),
        showAddRow: true,
      });
    }
  };

  useEffect(() => {
    loadInfo();
  }, []);

  const loadTable = (
    query: Query<ClientModel>
  ): Promise<QueryResult<ClientModel>> =>
    new Promise((resolve, reject) => {
      getAllClients({ data: { model }, query })
        .then((result) => {
          if (result.data.items) {
            const total =
              result.data.total_of_items > 9999
                ? 10000
                : result.data.total_of_items;
            return resolve({
              data: result.data.items,
              page: result.data.page - 1,
              totalCount: total,
            });
          }
          return resolve({
            data: [],
            page: result.data.page - 1,
            totalCount: result.data.total_of_items,
          });
        })
        .catch(() => {
          resolve({
            data: [],
            page: 0,
            totalCount: 0,
          });
          reject(
            toast.error(
              'Ocorreu um erro inesperado ao buscar os clientes. Tente novamente mais tarde.'
            )
          );
        });
    });

  const clientTableEdit = {
    onRowAdd: (data: { name: ClientModel }): Promise<void> =>
      new Promise((resolve) => {
        setTimeout(() => {
          const client = data.name;

          if (client?.id === undefined) {
            toast.warn(
              'É preciso selecionar um cliente para realizar o vínculo!'
            );
            resolve(reloadTable());
            resolve();
            return;
          }

          postClientsBonds({
            url: `clients/${client.id}/models/${model?.id}`,
            version: client.version,
          })
            .then((res) => {
              if ([200, 201, 204].includes(res.request.status)) {
                toast.success('Vínculo realizado com sucesso!');
                reloadTable();
                resolve();
                return;
              }
              const { reason } = JSON.parse(res.request.response);
              toast.error(`Não foi possível realizar o vínculo. ${reason}`);
            })
            .catch(() => {
              toast.error(
                'Não foi possível realizar o vínculo. Tente novamente mais tarde.'
              );
              resolve(reloadTable());
            });
        }, 1000);
      }),

    onRowDelete: (client: ClientModel): Promise<void> =>
      new Promise((resolve) => {
        deleteClientsModel({
          id: client.id,
          scopeID: id,
          version: client.version,
        })
          .then((res) => {
            if ([200, 201, 204].includes(res.request.status)) {
              toast.success('Vínculo com o cliente removido com sucesso!');
              reloadTable();
              resolve();
              return;
            }
            const { reason } = JSON.parse(res.request.response);
            toast.error(
              `Não foi possível remover o vínculo do cliente. ${reason}`
            );
            resolve(reloadTable());
          })
          .catch(() => {
            toast.error(
              'Não foi possível remover o vínculo, tente novamente mais tarde.'
            );
            resolve(reloadTable());
          });
      }),
  };

  const tableColumns = [
    nameAndIDColumn('/client/info'),
    IdColumn,
    ExternalIdColumn,
    documentColumn,
  ];

  if (loading) {
    return (
      <AbsoluteCenter>
        <LoadingSpinner />
      </AbsoluteCenter>
    );
  }

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Modelo', href: '/models' },
        {
          label: `${model?.description}`,
          href: `/models/info/${model?.id}`,
        },
        { label: 'Clientes' },
      ]}
      title={`${model?.description}`}
      handleAction={onButtonClick}
      actionText="Vincular novo cliente"
      buttonIcon={FiLink2}
      isDisabled
      showBackButton
    >
      <DefaultTable
        columns={tableColumns}
        data={loadTable}
        searchLabel="Busque por nome ou ID"
        accessURL="/client/info"
        editable={clientTableEdit}
        tableRef={tableRef}
      />
    </GenericForm>
  );
};

export default ModelsUpdateInfo;
