import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { FormLabel, InputGroup, Select } from '@chakra-ui/react';

import * as I from './interfaces';
import FormControl from './formControl';

const InputSelect: ForwardRefRenderFunction<
  HTMLSelectElement,
  I.InputSelectProps
> = ({ formcontrol, label, name, options, ...rest }, ref) => {
  return (
    <FormControl {...formcontrol} style={{ width: '100%' }}>
      <InputGroup>
        <Select
          {...rest}
          id={name}
          name={name}
          ref={ref}
          height="2.75rem"
          minWidth="10rem"
          borderRadius="large"
          variant="outline"
          fontSize="medium"
          borderColor="neutral.500"
          isDisabled={formcontrol.isDisabled}
          width="100%"
        >
          {options.map((item) => (
            <option
              key={`select-options_${name}_${item.value}`}
              value={item.value}
            >
              {item.option}
            </option>
          ))}
        </Select>

        <FormLabel htmlFor={name}>{label}</FormLabel>
      </InputGroup>
    </FormControl>
  );
};

export default forwardRef(InputSelect);
