import React, { ForwardRefRenderFunction } from 'react';
import { FormLabel, Textarea, InputGroup } from '@chakra-ui/react';
import FormControl from './formControl';

import * as I from './interfaces';

const InputTextArea: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  I.InputTextAreaProps
> = ({ activeLabel, formcontrol, label, name, ...rest }) => {
  return (
    <FormControl {...formcontrol}>
      <InputGroup>
        <Textarea
          id={name}
          name={name}
          placeholder=" "
          borderRadius="large"
          variant="outline"
          fontSize="medium"
          borderColor="neutral.500"
          isDisabled={formcontrol.isDisabled}
          data-testid="form-text-area"
          {...rest}
        />

        <FormLabel htmlFor={name} className={activeLabel ? 'active' : ''}>
          {label}
        </FormLabel>
      </InputGroup>
    </FormControl>
  );
};

export default InputTextArea;
