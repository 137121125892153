import React, { useCallback, useEffect, useRef, MutableRefObject } from 'react';
import { cleanFilters } from '../../components/base/v2/table/stores';
import GenericForm from '../../components/base/v2/genericForm';
import CreateTagsForm from './create';
import useDocumentTitle from '../../hooks/documentTitle';
import ViewTags from './view';

const Tags = (): React.ReactElement => {
  useDocumentTitle('Trex - Tags');
  const { resetsearchText, resetfilterBy, resetfilters } = cleanFilters();

  useEffect(() => {
    resetsearchText();
    resetfilterBy();
    resetfilters();
  }, [resetsearchText, resetfilterBy, resetfilters]);

  const useTableReload = (
    ref: MutableRefObject<{ onQueryChange?: () => void } | null>
  ) => {
    return useCallback(() => {
      ref.current?.onQueryChange?.();
    }, [ref]);
  };

  const clientTableRef = useRef(null);
  const groupTableRef = useRef(null);
  const flowTableRef = useRef(null);
  const modelTableRef = useRef(null);
  const partnerTableRef = useRef(null);

  const reloadTable = {
    client: useTableReload(clientTableRef),
    group: useTableReload(groupTableRef),
    flow: useTableReload(flowTableRef),
    model: useTableReload(modelTableRef),
    partner: useTableReload(partnerTableRef),
  };

  const tableRef = {
    client: clientTableRef,
    group: groupTableRef,
    flow: flowTableRef,
    model: modelTableRef,
    partner: partnerTableRef,
  };

  return (
    <GenericForm
      breadcrumb={[{ label: 'Início', href: '/' }, { label: 'Tags' }]}
      title="Tags"
      showBackButton
      backButtonUrl="/"
      secondaryActions={[
        {
          text: 'Listas',
          action: () => {
            window.location.href = '/tags-list';
          },
        },
      ]}
    >
      <>
        <CreateTagsForm reloadTable={reloadTable} />
        <ViewTags reloadTable={reloadTable} tableRef={tableRef} />
      </>
    </GenericForm>
  );
};

export default Tags;
