/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */

export const removeEmptyValues = (itemObject: Record<string, any>) => {
  return Object.entries(itemObject).reduce((result, [key, value]) => {
    const hasValue = Boolean(value);
    if (hasValue) {
      result[key] = value;
    }
    return result;
  }, {} as Record<string, any>);
};
