import styled from 'styled-components';
import { Grid } from '@chakra-ui/react';

export const Container = styled.main`
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  min-height: 100vh;
  padding-right: 2.5rem;
  padding-left: 1.875rem;
  position: relative;
  padding-top: ${({ theme }) => theme.spacing(6.5)}px;
`;

export const GridContainer = styled(Grid).attrs(() => ({
  templateColumns: 'repeat(2, 1fr)',
  gap: 6,
}))`
  width: 100%;
  height: 100%;
  word-break: break-word;
  background-color: ${({ theme }) => theme.colors.v2.white[100]};
  padding: ${({ theme }) => theme.spacing(3)}px;
  padding-top: 0;
  border-radius: 12px;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
`;
