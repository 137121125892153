/* eslint-disable no-nested-ternary */
import React from 'react';
import { GridItem } from '@chakra-ui/react';
import * as S from './styles';
import Stat from '../../../stat';
import { theme } from '../../../../styles/theme';
import Badge from '../../../badge';
import { checkString } from '../../../../utils/check_values';
import { dateExpiresFormater } from '../../../../utils/format_date';
import * as I from '../../../../interfaces/order';
import { handleBrands, handleTokenItem, handleWalletType } from './utils';

interface CreditCardProps {
  payment: I.PaymentModel;
}

const CreditCard = ({ payment }: CreditCardProps): React.ReactElement => {
  const tokenizedText = handleTokenItem(payment.is_tokenized);

  return (
    <S.CreditCardContainer>
      <GridItem
        colSpan={2}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}
      >
        {handleBrands(payment.brand)} {handleWalletType(payment.wallet_type)}
      </GridItem>

      <GridItem colSpan={3} style={{ justifySelf: 'end' }}>
        <Badge
          label={`${checkString(payment.level)} - ${checkString(
            payment['type@display'].toUpperCase()
          )}`}
          schema="green"
          fontSize="small"
        />
      </GridItem>

      <GridItem colSpan={3}>
        <Stat
          title="Titular"
          fontColor={theme.colors.v2.white[100]}
          fontWeight={400}
          text={checkString(payment.holder_name)}
          identifier="name"
          copy={checkString(payment.holder_name)}
        />
      </GridItem>

      <GridItem colSpan={1} style={{ justifySelf: 'end' }}>
        <Stat
          title="ECI"
          fontColor={theme.colors.v2.white[100]}
          fontWeight={400}
          text={payment.eci === 'não informado' ? '-' : payment.eci}
        />
      </GridItem>

      <GridItem colSpan={1} style={{ justifySelf: 'end' }}>
        <Stat
          title="Tokenizado"
          fontColor={theme.colors.v2.white[100]}
          fontWeight={400}
          text={tokenizedText}
        />
      </GridItem>

      <GridItem colSpan={3}>
        <Stat
          title="Número do cartão"
          fontColor={theme.colors.v2.white[100]}
          fontWeight={400}
          text={`${checkString(payment.bin)}****${checkString(payment.last_4)}`}
          identifier="card_number"
          copy={`${checkString(payment.bin)}****${checkString(payment.last_4)}`}
        />
      </GridItem>

      <GridItem colSpan={2} style={{ justifySelf: 'end' }}>
        <Stat
          title="Válido até:"
          fontColor={theme.colors.v2.white[100]}
          fontWeight={400}
          text={dateExpiresFormater(payment.expires_at || null)}
          identifier="expiration_date"
          copy={dateExpiresFormater(payment.expires_at || null)}
        />
      </GridItem>
    </S.CreditCardContainer>
  );
};

export default CreditCard;
