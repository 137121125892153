import React from 'react';
import { RetentionModel } from '../../interfaces/retentionFlow';
import { useRetentionFlow } from '../../hooks/api/retentionFlow';
import {
  createdAtColumn,
  descriptionAndIDColumn,
  IdColumn,
  modifiedAtColumn,
} from '../../components/base/v2/table/columns';
import GenericListPage from '../genericList';

const RetentionFlow = () => {
  const { getRetentionFlow } = useRetentionFlow();

  const tableColumns = [
    descriptionAndIDColumn('/retention-flow/info'),
    IdColumn,
    createdAtColumn,
    modifiedAtColumn,
  ];

  return (
    <GenericListPage<RetentionModel>
      actionButton={{
        pushPath: '/retention-flow/create',
        text: 'Criar novo fluxo',
      }}
      breadcrumb={{
        items: [
          { label: 'Início', href: '/' },
          { label: 'Fluxos de retenção' },
        ],
      }}
      title="Fluxos de retenção"
      table={{
        fetch: {
          apiCall: getRetentionFlow,
          title: 'regras',
        },
        body: {
          columns: tableColumns,
          searchLabel: 'Busque por nome, ID, regra ou nó',
          accessURL: '/retention-flow/info',
          isSearch: false,
          hasQuery: true,
        },
      }}
    />
  );
};

export default RetentionFlow;
