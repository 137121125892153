import { atomWithReset, useResetAtom } from 'jotai/utils';
import { NodeModel } from '../../../interfaces/flow';

export const clonedNodesAtom = atomWithReset<NodeModel[]>([]);
export const isEditingFlow = atomWithReset(false);
export const isEditingNodeAtom = atomWithReset(false);

export const ResetAtom = () => {
  const resetClonedNodesAtom = useResetAtom(clonedNodesAtom);
  const resetIsEditingFlow = useResetAtom(isEditingFlow);
  const resetIsEditingNodeAtom = useResetAtom(isEditingNodeAtom);

  return {
    resetClonedNodesAtom,
    resetIsEditingFlow,
    resetIsEditingNodeAtom,
  };
};
