import { Box, Stack, Text } from '@chakra-ui/react';
import styled from 'styled-components';

export const BoxLoading = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.colors.v2.gray[100],
  height: '100vh',
  width: '100vw',
}));

export const EmailText = styled(Text)(() => ({
  fontSize: 14,
}));

export const GroupCard = styled(Box)({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
  flex: 1,
  width: '100%,',
});

export const GroupInfo = styled(Box)({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
  flex: 1,
});

export const GroupFilter = styled(Box)({
  display: 'flex',
  gap: 16,
  alignItems: 'center',
  '& > div': {
    width: '100%',
  },
});

export const FilterBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  flex: 1,
  backgroundColor: theme.colors.v2.white[100],
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  alignItems: 'baseline',
  '& > div': {
    marginTop: '0 !important',
    width: '49.5%',
  },
}));

export const StyledSimpleGrid = styled(Stack)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  flex: 1,
  gap: theme.spacing(2),
}));

export const GridGraphs = styled('div')({
  margin: '0 !important',
  width: '100%',
});
