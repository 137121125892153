import React, { useEffect, useState } from 'react';
import {
  Accordion,
  Box,
  CheckboxGroup,
  Icon,
  Stack,
  useCheckboxGroup,
  useDisclosure,
} from '@chakra-ui/react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { CgAddR } from 'react-icons/cg';
import { FaRegClone } from 'react-icons/fa';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { useAtom } from 'jotai';
import Node from '../../../components/base/v2/flow/node';
import useRulesFlows from '../../../hooks/api/rulesFlows';
import BondTags from '../../../components/bondTags';
import Footer from '../../../components/base/v2/footer';
import SubmenuFlows from './submenu';
import Button from '../../../components/base/v2/button';
import CloneNodeModal from '../../../components/base/v2/flow/cloneNodeModal';
import LoadingSpinner from '../../../components/loadingSpinner';
import { appendNodeObject, cloneNodes, validateSchema } from './utils';
import { handleTagsFormToScopeModel } from '../../../components/bondTags/utils';
import { useRulesFlow } from '../../../providers/rules_flow';
import {
  clonedNodesAtom,
  isEditingFlow,
  isEditingNodeAtom,
  ResetAtom,
} from './store';

import * as S from './styles';
import * as I from './interfaces';
import * as I2 from '../../../components/bondTags/interfaces';
import * as I3 from '../../../interfaces/flow';

const FlowUpsertForm: React.FC<I.FlowUpsertFormProps> = ({
  flow,
  handleSubmit,
  isLoaded,
  isBlock,
  isRetention,
}) => {
  const [cloneID, setCloneID] = useState(flow?.id ?? '');
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useAtom(isEditingFlow);
  const [, setIsNodeEditing] = useAtom(isEditingNodeAtom);
  const [clonedNodes, setClonedNodes] = useAtom(clonedNodesAtom);
  const { resetClonedNodesAtom, resetIsEditingNodeAtom } = ResetAtom();

  const { updateFlow } = useRulesFlows();
  const { getNewVersion } = useRulesFlow();
  const { value: checkboxesValue, setValue: setCheckbox } = useCheckboxGroup();
  const { push } = useHistory();

  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: onDescartOpen,
    onClose: onDescartClose,
    isOpen: isDescartOpen,
  } = useDisclosure();

  const methods = useForm({
    resolver: yupResolver(validateSchema),
  });

  const { control, setValue, getValues } = methods;

  const handleEditing = () => {
    setIsEditing(!isEditing);
  };

  const {
    fields: nodes,
    move: moveNode,
    append: appendNode,
    remove: removeNode,
  } = useFieldArray({
    control,
    name: 'nodes',
  });

  const handleTagSubmit = async ({
    data,
    type,
    scope,
  }: I2.FormToScopeModelType) => {
    const formData = handleTagsFormToScopeModel({
      scope,
      data,
      type,
    });

    return updateFlow({
      id: scope.id,
      data: formData,
      version: flow.version,
    }).finally(() => {
      getNewVersion();
      setIsEditing(false);
      setIsNodeEditing(false);
    });
  };

  const handleDescart = async (): Promise<void> => {
    try {
      setIsLoading(true);
      methods.reset(flow);
      handleEditing();
      setIsNodeEditing(false);
    } finally {
      onDescartClose();
      setIsLoading(false);
    }
  };

  const handleDragEnd = ({ source, destination }: DropResult): void => {
    if (destination) {
      moveNode(source.index, destination.index);
    }
  };

  const addNode = (): void => {
    setIsNodeEditing(true);
    appendNode(appendNodeObject);
  };

  const handleAppendToDuplication = async (
    node: I3.NodeModel,
    index: number
  ): Promise<void> => {
    appendNode(node);
    setValue(`nodes.${nodes.length + index}`, node);
  };

  const handleCloneNode = async (): Promise<void> => {
    const values = checkboxesValue.map((item) => getValues(item as string));

    if (flow?.id && cloneID !== flow?.id && !isBlock && !isRetention) {
      setClonedNodes(values);
      handleEditing();
      setCheckbox([]);
      onClose();
      push(`/rules-flow/info/${cloneID}`);
      return;
    }

    cloneNodes(values, handleAppendToDuplication);
    setCheckbox([]);
    onClose();
  };

  useEffect(() => {
    if (clonedNodes.length > 0 && nodes.length > 0) {
      cloneNodes(clonedNodes, handleAppendToDuplication);
      handleEditing();
      resetClonedNodesAtom();
    }
  }, [cloneID === flow?.id]);

  useEffect(() => {
    if (flow) {
      setIsLoading(false);
      methods.reset(flow);
      resetIsEditingNodeAtom();
    }
  }, [flow]);

  if (isLoading || !isLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {flow.id && !isBlock && !isRetention && (
        <BondTags
          scope={flow as I3.FlowModel}
          scopeName="flow"
          onSubmitScope={handleTagSubmit}
        />
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)} id="flow-form">
          <Box background="neutral.100" pb="regular">
            <SubmenuFlows
              isEditing={isEditing}
              isBlock={isBlock}
              isRetention={isRetention}
              flow={flow}
            />
          </Box>

          <S.Container>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="node-list-dnd-droppable">
                {(dropProvided) => (
                  <div
                    ref={dropProvided.innerRef}
                    {...dropProvided.droppableProps}
                  >
                    <Accordion allowToggle allowMultiple>
                      <CheckboxGroup
                        colorScheme="green"
                        value={checkboxesValue}
                      >
                        <Stack pb="regular" spacing="smallest">
                          {nodes.map((node, index) => {
                            const key = `node_${node.id}-${index}`;
                            return (
                              <Node
                                flowId={flow?.id}
                                key={key}
                                node={node}
                                nodeIndex={index}
                                nodes={nodes}
                                moveNode={moveNode}
                                removeNode={removeNode}
                                setCheckbox={setCheckbox}
                                isBlock={isBlock}
                                isRetention={isRetention}
                              />
                            );
                          })}
                        </Stack>
                      </CheckboxGroup>
                    </Accordion>
                    {dropProvided.placeholder}
                  </div>
                )}
              </Droppable>

              {isEditing && (
                <Stack
                  direction="row"
                  background="white"
                  borderRadius="extra-large"
                  p="medium"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    onClick={isBlock || isRetention ? handleCloneNode : onOpen}
                    leftIcon={<Icon as={FaRegClone} />}
                    isDisabled={checkboxesValue.length === 0}
                  >
                    Clonar nó(s)
                  </Button>
                  <Button leftIcon={<Icon as={CgAddR} />} onClick={addNode}>
                    Adicionar nó
                  </Button>
                </Stack>
              )}
            </DragDropContext>
          </S.Container>
          {isEditing && (
            <Footer
              id={flow.id}
              label={isBlock ? 'Bloco' : 'Fluxo'}
              formName="flow-form"
              onDescartOpen={onDescartOpen}
              isDescartOpen={isDescartOpen}
              onDescartClose={onDescartClose}
              handleDescart={handleDescart}
            />
          )}

          <CloneNodeModal
            onClose={onClose}
            isOpen={isOpen}
            action={handleCloneNode}
            flow={cloneID}
            setFlow={setCloneID}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default FlowUpsertForm;
