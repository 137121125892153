import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BiCommentDetail } from 'react-icons/bi';
import { Icon } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import GenericForm from '../../../components/base/v2/genericForm';
import CloneFlowModal from '../../../components/base/v2/flow/cloneFlowModal';
import useRulesFlows from '../../../hooks/api/rulesFlows';
import FlowUpsertForm from '../../../sections/flow/form';
import { useRulesFlow } from '../../../providers/rules_flow';
import { constants } from '../../../constants';
import { formatConstants } from '../../../utils/formatConstants';
import { handleDataToForm, handleFormDataToModel } from '../utils';
import { isEditingFlow, ResetAtom } from '../../../sections/flow/form/store';

import * as I from '../../../interfaces/flow';

const RulesFlowInfo = () => {
  const { rulesData, dataContext, isLoaded, getNewVersion } = useRulesFlow();
  const { updateFlow } = useRulesFlows();
  const { push } = useHistory();
  const [isEditing, setIsEditing] = useAtom(isEditingFlow);
  const { resetIsEditingFlow, resetIsEditingNodeAtom } = ResetAtom();

  const handleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleDirectToList = (link: string) => {
    push(`/rules-flow/info/${link}/${dataContext?.id}`);
  };

  const handleSubmit = async (data: I.FluxModelForm) => {
    try {
      await updateFlow({
        id: dataContext.id,
        data: handleFormDataToModel(rulesData.rules, data),
        version: dataContext.version,
      });
      handleEditing();
      getNewVersion();

      const message = formatConstants(constants.updatedWithSuccess, 'Fluxo');
      toast.success(message);
    } catch (err: unknown) {
      if (err instanceof Error) {
        toast.error(`${constants.errorToSubmitWithReason}: ${err.message}`);
      }
    }
  };

  useEffect(() => {
    resetIsEditingFlow();
    resetIsEditingNodeAtom();
  }, []);

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Fluxo de Regras', href: '/rules-flow' },
        { label: dataContext?.description },
      ]}
      title={dataContext?.description}
      isDisabled={!isEditing}
      handleAction={handleEditing}
      actionText="Editar Fluxo"
      backButtonUrl="/rules-flow"
      copyText={dataContext?.id}
      secondaryActions={[
        {
          text: 'Comentários',
          action: () => handleDirectToList('comments'),
          icon: <Icon as={BiCommentDetail} fontSize="18px" />,
        },
        {
          text: 'Clientes',
          action: () => handleDirectToList('clients'),
        },
      ]}
      menuItem={<CloneFlowModal />}
      showBackButton
    >
      <FlowUpsertForm
        flow={handleDataToForm(dataContext)}
        isLoaded={isLoaded}
        handleSubmit={handleSubmit}
      />
    </GenericForm>
  );
};

export default RulesFlowInfo;
