import React, { useState, useEffect, useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import { useHistory } from 'react-router-dom';
import { ActionsListModel } from '../../../interfaces/actions_list';
import ActionsListForm from '../../../components/forms/actions_list';
import useActionLists from '../../../hooks/api/actionLists';
import useQuery from '../../../hooks/queryParams';
import GenericForm from '../../../components/base/v2/genericForm';
import LoadingSpinner from '../../../components/loadingSpinner';
import { LoadingContainer } from '../../clients/info/styles';

const ActionsListUpdate: React.FC = () => {
  const { keycloak } = useKeycloak();
  const { getOneActionList } = useActionLists();
  const { push } = useHistory();

  const [action, setAction] = useState({} as ActionsListModel);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const query = useQuery();
  const id = query.get('id') ?? undefined;

  const loadInfo = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    const response = await getOneActionList(id);
    setAction(response.data);
    setIsLoading(false);
  }, [keycloak.token]);

  useEffect(() => {
    loadInfo();
  }, []);

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  const handleDirectToList = () => {
    push(`/actions-list/info/list?id=${action?.id}`);
  };

  const handleEditing = () => {
    setIsDisabled(!isDisabled);
  };

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Listas', href: '/actions-list' },
        { label: action?.name ? action.name : 'Cadastro de Lista' },
      ]}
      title={action?.name ? action.name : 'Cadastro de Lista'}
      showBackButton
      backButtonUrl="/actions-list"
      actionText="Editar Lista"
      handleAction={handleEditing}
      isDisabled={isDisabled}
      secondaryActions={[
        {
          text: 'Clientes',
          action: handleDirectToList,
        },
      ]}
    >
      <ActionsListForm initialData={action} isDisabled={isDisabled} />
    </GenericForm>
  );
};

export default ActionsListUpdate;
