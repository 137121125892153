import React from 'react';
import {
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  AbsoluteCenter,
} from '@chakra-ui/react';
import Button from '../button';
import LoadingSpinner from '../../../loadingSpinner';

import * as I from './interfaces';

const DoubleCheckModal = ({
  isLoading,
  isOpen,
  onClose,
  title,
  description,
  children,
  modal,
  primaryButton,
  closeButton,
}: I.DoubleCheckModalProps): React.ReactElement => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered {...modal}>
      <ModalOverlay />

      <ModalContent>
        {isLoading ? (
          <ModalBody minHeight="35vh">
            <AbsoluteCenter>
              <LoadingSpinner />
            </AbsoluteCenter>
          </ModalBody>
        ) : (
          <>
            <ModalHeader
              borderBottom="1px solid"
              borderBottomColor="neutral.300"
            >
              <Text fontWeight="bold" fontSize="extra-large">
                {title}
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody padding="24px">
              {description}
              {children}
            </ModalBody>
            <ModalFooter>
              <Button
                data-testid="DoubleCheckModal-button--back"
                mr={3}
                onClick={closeButton?.action ?? onClose}
                colorScheme={closeButton?.colorScheme}
              >
                {closeButton?.text ?? 'Voltar'}
              </Button>

              <Button
                type="submit"
                data-testid="DoubleCheckModal-button-submit"
                form={primaryButton?.formName}
                onClick={primaryButton?.action}
                colorScheme={primaryButton?.colorScheme}
              >
                {primaryButton?.text ?? 'Enviar'}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default DoubleCheckModal;
