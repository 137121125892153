/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiLink2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { ClientModel } from '../../../../interfaces/client';
import useClients from '../../../../hooks/api/clients';
import { useRetentionFlow } from '../../../../hooks/api/retentionFlow';
import {
  ExternalIdColumn,
  IdColumn,
  documentColumn,
  nameAndIDColumn,
} from '../../../../components/base/v2/table/columns';
import { RetentionModel } from '../../../../interfaces/retentionFlow';
import GenericListPage from '../../../genericList';
import LoadingSpinner from '../../../../components/loadingSpinner';
import { LoadingContainer } from '../../../clients/info/styles';
import { constants } from '../../../../constants';
import { formatConstants } from '../../../../utils/formatConstants';

const RetentionFlowClientsInfo = (): React.ReactElement => {
  const [flow, setFlow] = useState<RetentionModel>();
  const [isLoading, setIsLoading] = useState(true);

  const { getClients, postClientsBonds, deleteClientsRetention } = useClients();
  const { getRetentionFlowById } = useRetentionFlow();
  const tableRef = useRef<any>();
  const { id } = useParams<{ id: string }>();

  const onButtonClick = () => {
    if (tableRef.current) {
      tableRef.current.dataManager?.changeRowEditing();
      tableRef.current.setState({
        ...tableRef.current.dataManager?.getRenderState(),
        showAddRow: true,
      });
    }
  };

  const reloadTable = useCallback(() => {
    tableRef.current?.onQueryChange();
  }, []);

  const clientTableEdit = {
    onRowAdd: async (data: { name: ClientModel }): Promise<void> => {
      try {
        const client = data.name;

        await postClientsBonds({
          url: `clients/${client.id}/retention-flows/${id}`,
          version: client.version,
        });

        const message = formatConstants(constants.linkWithSuccess, 'Cliente');
        toast.success(message);
        reloadTable();
      } catch (err: unknown) {
        if (err instanceof Error) {
          const message = formatConstants(
            constants.errorToLinkWithReason,
            'cliente'
          );
          toast.error(`${message}: ${err.message}`);
        }
      }
    },
    onRowDelete: async (client: ClientModel): Promise<void> => {
      try {
        await deleteClientsRetention({
          id: client.id,
          flowID: client.retention_flow_id ?? '',
          version: client.version ?? '',
        });

        const message = formatConstants(constants.unlinkWithSuccess, 'Cliente');
        toast.success(message);
        reloadTable();
      } catch (err: unknown) {
        if (err instanceof Error) {
          const message = formatConstants(
            constants.errorToUnlinkWithReason,
            'cliente'
          );
          toast.error(`${message}: ${err.message}`);
        }
      }
    },
  };

  useEffect(() => {
    const loadInfo = async () => {
      try {
        setIsLoading(true);
        const { data } = await getRetentionFlowById(id);
        setFlow(data);
      } catch {
        toast.error('Erro ao carregar a lista, tente novamente mais tarde.');
      } finally {
        setIsLoading(false);
      }
    };

    loadInfo();
  }, []);

  const rulesFlowColumns = [
    nameAndIDColumn('/client/info'),
    IdColumn,
    documentColumn,
    ExternalIdColumn,
  ];

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <GenericListPage<ClientModel>
      breadcrumb={{
        items: [
          { label: 'Início', href: '/' },
          { label: 'Fluxo de Retenção', href: '/retention-flow' },
          {
            label: flow?.description ?? 'carregando...',
            href: `/retention-flow/info/${id}`,
          },
          { label: 'Clientes' },
        ],
      }}
      actionButton={{
        text: 'Vincular novo cliente',
        icon: FiLink2,
        action: onButtonClick,
      }}
      title="Fluxos de retenção"
      showBackButton
      table={{
        fetch: {
          apiCall: (query) => getClients({ ...query, retentionFlow: id }),
          title: 'regras',
        },
        body: {
          columns: rulesFlowColumns,
          searchLabel: 'Busque por nome ou ID',
          accessURL: '/client/info',
          editable: clientTableEdit,
          tableRef,
        },
      }}
    />
  );
};

export default RetentionFlowClientsInfo;
