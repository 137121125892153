import React, { useState, useEffect } from 'react';
import AutoSuggest from 'react-autosuggest';
import { trexApi } from '../../../services/api';
import Container from './Container';
import { getUUID } from '../../../services/storage';
import * as I from '../../../interfaces/components/base/autocomplete';

export default function Autocomplete({
  onChange,
}: I.AutoCompletePropsModel): React.ReactElement {
  const UUID = getUUID();
  const [clientsList, setClientsList] = useState<I.AutoCompleteNameModel[]>([
    { id: '', name: '' },
  ]);
  const [dataValue, setDataValue] = useState('');
  const [suggestions, setSuggestions] = useState<I.AutoCompleteNameModel[]>([
    { id: '', name: '' },
  ]);

  const getClients = (): Promise<React.ReactNode> =>
    new Promise((resolve, reject) => {
      trexApi
        .get('clients', {
          params: {
            page: 1,
            'page-size': 10,
            search: dataValue,
          },
          headers: {
            'X-SetID': UUID,
          },
        })
        .then((result) => {
          if (result.data.items) {
            setClientsList(result.data.items);
          }
        })
        .catch(() => {
          reject();
        });
    });

  function getSuggestions(value: string): Array<{ id: string; name: string }> {
    return clientsList.filter((company) =>
      `${company.name.toLowerCase()} (ID: ${company.id.toLowerCase()})`.includes(
        value.toLowerCase().trim()
      )
    );
  }

  const callAction = (suggestion: I.AutoCompleteNameModel): void => {
    if (onChange) {
      onChange(suggestion);
    }
  };

  useEffect(() => {
    getClients();
  }, [dataValue]);

  return (
    <Container>
      <AutoSuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={(): void => setSuggestions([])}
        onSuggestionsFetchRequested={({ value }): void => {
          setDataValue(value);
          setSuggestions(getSuggestions(value));
        }}
        onSuggestionSelected={(_, { suggestion }): void =>
          callAction(suggestion)
        }
        getSuggestionValue={(suggestion): string => suggestion.name}
        renderSuggestion={(suggestion): React.ReactElement => (
          <span>{`${suggestion.name} (ID: ${suggestion.id.substring(
            0,
            6
          )}...)`}</span>
        )}
        inputProps={{
          id: 'suggestionField',
          placeholder: 'Pesquisar',
          value: dataValue,
          onChange: (_, { newValue }): void => {
            setDataValue(newValue);
          },
        }}
        highlightFirstSuggestion
      />
    </Container>
  );
}

Autocomplete.defaultProps = { onChange: null };
