import styled from 'styled-components';
import { Editable, EditablePreview } from '@chakra-ui/react';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto ${({ theme }) => theme.spacing(3)}px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.v2.white[100]};
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(3)}px;
  height: ${({ theme }) => theme.spacing(11.5)}px;
`;

export const StyledEditable = styled(Editable)`
  max-width: ${({ theme }) => theme.spacing(5)}px;
  min-width: ${({ theme }) => theme.spacing(5)}px;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  background: ${({ theme }) => theme.colors.v2.gray[400]};
  text-align: center;
`;

export const StyledEditablePreview = styled(EditablePreview)`
  width: '100%';
  :hover {
    background: ${({ theme }) => theme.colors.v2.white[200]};
  }
`;
