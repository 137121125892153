/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useState } from 'react';
import {
  AccordionIcon,
  AccordionPanel,
  Divider,
  Icon,
  Text,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { TbCirclePlus } from 'react-icons/tb';
import { IconType } from 'react-icons/lib';
import { HiOutlineTag } from 'react-icons/hi';
import { BiEditAlt } from 'react-icons/bi';
import { RiCloseCircleFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { ReactComponent as shopIcon } from '../../assets/icons/darkShopIcon.svg';

import AutocompleteTags from '../base/v2/autocompleteTags';
import IconButton from '../icons/iconButton';
import LoadingSpinner from '../loadingSpinner';
import Badge from '../badge';

import * as I from './interfaces';
import * as S from './styles';

const BondTags = ({
  scope,
  scopeName,
  onSubmitScope,
}: I.BondTagsProps): React.ReactElement => {
  const [submitting, setSubmitting] = useState(false);
  const [scopeData, setScopeData] = useState(scope);
  const [isEditing, setIsEditing] = useState(false);

  const methods = useForm();
  const { handleSubmit, register, setValue, reset, watch } = methods;
  const isInputEmpty = !watch('tag_id');

  const handleUpdateClient = async ({ data, type }: I.TagSubmitType) => {
    setSubmitting(true);

    try {
      const res = await onSubmitScope({ data, type, scope: scopeData });
      if ([200, 201, 204].includes(res.request.status)) {
        toast.success('Tag atualizada com sucesso!');

        reset();
        setScopeData(res.data);
        setSubmitting(false);
        setIsEditing(false);
        return;
      }

      const { reason } = JSON.parse(res.request.response);
      toast.error(`Não foi possível atualizar a tag ao cliente. ${reason}`);
      setSubmitting(false);
    } catch {
      toast.error(
        'Não foi possível atualizar a tag ao cliente. Tente novamente mais tarde.'
      );
      setSubmitting(false);
    }
  };

  const onSubmit = async (data: I.TagsBondForm) => {
    handleUpdateClient({ data, type: 'add' });
  };

  const handleViewTags = useCallback(() => {
    if (scopeData.tags) {
      return (
        <S.TagsRow>
          <S.TagsContainer>
            {scopeData.tags.map((tag, index) => {
              const key = `tag-${index}`;
              return (
                <Badge
                  key={key}
                  mr={2}
                  mb={4}
                  schema="gray"
                  transparent
                  label={tag.value}
                  leftIcon={!isEditing ? (shopIcon as IconType) : undefined}
                  data-testid={`tag-${tag.value}`}
                >
                  {isEditing && (
                    <IconButton
                      as={RiCloseCircleFill}
                      onClick={() =>
                        handleUpdateClient({
                          data: { tag_id: tag.id },
                          type: 'remove',
                        })
                      }
                    />
                  )}
                </Badge>
              );
            })}
          </S.TagsContainer>
          <S.TagsEditButton onClick={() => setIsEditing(!isEditing)}>
            <Icon as={BiEditAlt} color="#303742" fontSize="22px" />
          </S.TagsEditButton>
        </S.TagsRow>
      );
    }
    return <Text m={6}>Nenhuma tag vinculada.</Text>;
  }, [scopeData, isEditing]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.StyledAccordion allowMultiple data-testid="tag-accordion-container">
          <S.StyledAccordionItem>
            <S.AccordionHeader>
              <S.AccordionTitle data-testid="tag-accordion-title">
                <Icon
                  as={HiOutlineTag}
                  color="#303742"
                  fontSize="28px"
                  mr="8px"
                />
                Tags
              </S.AccordionTitle>
              <S.StyledAccordionButton data-testid="tag-accordion-button">
                <AccordionIcon />
              </S.StyledAccordionButton>
            </S.AccordionHeader>
            <AccordionPanel p={0}>
              <S.Row>
                <S.AccordionSubTitle>Vincular TAGs</S.AccordionSubTitle>
                <AutocompleteTags
                  {...register('tag_id')}
                  action={setValue}
                  scopeName={scopeName}
                />
                <S.StyledButton
                  type="submit"
                  isLoading={submitting}
                  disabled={isInputEmpty}
                >
                  <Icon as={TbCirclePlus} mr={1} />
                  Adicionar tag
                </S.StyledButton>
              </S.Row>
              <Divider />
              {submitting ? (
                <LoadingSpinner width="3rem" height="3rem" />
              ) : (
                handleViewTags()
              )}
            </AccordionPanel>
          </S.StyledAccordionItem>
        </S.StyledAccordion>
      </form>
    </FormProvider>
  );
};

export default BondTags;
