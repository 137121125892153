import React, { useEffect } from 'react';

import {
  actionsListTypeColumn,
  IdColumn,
  listNameAndIDColumn,
  nameAndIDColumn,
  targetColumn,
  expiresAtColumn,
  actionColumn,
} from '../../components/base/v2/table/columns';
import GenericListPage from '../genericList';
import useActionLists from '../../hooks/api/actionLists';
import { ActionsListModel } from '../../interfaces/actions_list';
import useDocumentTitle from '../../hooks/documentTitle';
import { cleanFilters } from '../../components/base/v2/table/stores';

const ActionsList: React.FC = () => {
  useDocumentTitle('Trex - Listas');
  const { getActions, getActionsItems } = useActionLists();
  const { resetsearchText, resetfilterBy, resetfilters } = cleanFilters();

  useEffect(() => {
    resetsearchText();
    resetfilterBy();
    resetfilters();
  }, []);

  return (
    <GenericListPage<ActionsListModel>
      actionButton={{
        pushPath: '/actions-list/create',
        text: ' Criar nova lista',
      }}
      breadcrumb={{
        items: [{ label: 'Início', href: '/' }, { label: 'Listas' }],
      }}
      title="Listas"
      table={[
        {
          fetch: {
            apiCall: getActions,
            title: 'listas',
          },
          body: {
            title: 'Lista',
            columns: [nameAndIDColumn('/actions-list/info'), IdColumn],
            searchLabel: 'Busque por nome ou ID de uma lista',
            accessURL: '/actions-list/info',
            hasQuery: true,
          },
        },
        {
          fetch: {
            apiCall: getActionsItems,
            title: 'listas',
          },
          body: {
            title: 'Item',
            columns: [
              listNameAndIDColumn('/actions-list/info'),
              IdColumn,
              actionsListTypeColumn,
              actionColumn,
              targetColumn,
              expiresAtColumn,
            ],
            hasQuery: true,
            searchLabel: 'Busque por um valor',
          },
        },
      ]}
    />
  );
};

export default ActionsList;
