/* eslint-disable import/prefer-default-export */
import { AxiosError, AxiosResponse } from 'axios';
import { getUUID } from '../../../services/storage';
import { trexApi } from '../../../services/api';
import { dateFormaterAmerican } from '../../../utils/format_date';
import { ChartModel } from './interface';

export const useCharts = () => {
  const UUID = getUUID();

  const headers = {
    TZ: Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...(UUID && { 'X-SetID': UUID }),
  };

  const getCharts = async (
    startDate: Date,
    endDate: Date
  ): Promise<AxiosResponse<ChartModel>> => {
    try {
      const response = await trexApi.get<ChartModel>(
        `users/metrics?start-date=${dateFormaterAmerican(
          startDate
        )}&end-date=${dateFormaterAmerican(endDate)}&interval=daily`,
        {
          ...(UUID && { headers }),
        }
      );
      return response;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        const { response } = err;
        throw new Error(response?.data.reason);
      }

      throw err;
    }
  };

  return { getCharts };
};
