/* eslint-disable import/prefer-default-export */

export const constants = {
  doNotLoadContent:
    'Não foi possível buscar {{content}}. Tente novamente mais tarde.',
  errorToLoadContent:
    'Ocorreu um erro inesperado ao buscar {{content}}. Tente novamente mais tarde.',
  errorToSubmitContent:
    'Ocorreu um erro inesperado. Tente novamente mais tarde.',
  errorToSubmitWithReason: 'Ocorreu um erro ao enviar o formulário',
  errorToUnlinkWithReason: 'Ocorreu um erro ao desvincular o {{content}}',
  errorToLinkWithReason: 'Ocorreu um erro ao vincular o {{content}}',
  linkWithSuccess: '{{content}} vinculado com sucesso!',
  unlinkWithSuccess: '{{content}} desvinculado com sucesso!',
  updatedWithSuccess: '{{content}} alterado com sucesso!',
  publishedWithSuccess: '{{content}} publicado com sucesso!',
  errorToLoadClients: 'Erro ao buscar os clientes, tente novamente mais tarde.',
};
