import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useAtom } from 'jotai';
import GenericForm from '../../../components/base/v2/genericForm';
import FlowUpsertForm from '../../../sections/flow/form';
import { emptyRetentionFlow, handleFormDataToRetentionModel } from '../utils';
import { useRetentionFlow } from '../../../hooks/api/retentionFlow';
import { formatConstants } from '../../../utils/formatConstants';
import { constants } from '../../../constants';
import { isEditingFlow } from '../../../sections/flow/form/store';

import { RetentionModel } from '../../../interfaces/retentionFlow';
import { FluxModelForm } from '../../../interfaces/flow';

const RetentionFlowCreate = () => {
  const [, setIsEditing] = useAtom(isEditingFlow);

  const { push } = useHistory();
  const { createRetentionFlow } = useRetentionFlow();

  const handleSubmit = async (data: FluxModelForm | RetentionModel) => {
    try {
      const res = await createRetentionFlow({
        data: handleFormDataToRetentionModel(data as RetentionModel),
      });
      if ([200, 201, 204].includes(res.request.status)) {
        const message = formatConstants(
          constants.publishedWithSuccess,
          'Fluxo de retenção'
        );
        toast.success(message);
        push(`/retention-flow/info/${res.data.id}`);
        return;
      }

      const { reason } = JSON.parse(res.request.response);
      toast.error(`${constants.errorToSubmitWithReason}: ${reason}`);
    } catch {
      toast.error(constants.errorToSubmitContent);
    }
  };

  useEffect(() => {
    setIsEditing(true);
  });

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Fluxo de retenção', href: '/retention-flow' },
        { label: 'Cadastro' },
      ]}
      title="Cadastro de Fluxo de retenção"
      backButtonUrl="/retention-flow"
      showBackButton
    >
      <FlowUpsertForm
        flow={emptyRetentionFlow}
        handleSubmit={handleSubmit}
        isLoaded
        isRetention
      />
    </GenericForm>
  );
};

export default RetentionFlowCreate;
