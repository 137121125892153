export const emptyFlowObject = {
  allow_review: false,
  description: '',
  nodes: [
    {
      title: '',
      operator: '',
      action: '',
      error_policy: '',
      terms: [
        {
          complement: false,
          error_policy: '',
          rule: {
            name: '',
          },
        },
      ],
    },
  ],
  comment: {
    title: '',
    description: '',
    author: '',
  },
  flow_change_history: [],
};

export const emptyRulesDataObject = {
  rules: [],
  rulesNames: [],
};
