import axios from 'axios';
import { toast } from 'react-toastify';
import { trexApi } from '../../../services/api';
import { getUUID } from '../../../services/storage';
import { CreateTagType, GetAllTagsType } from '../interfaces';
import { handleApiRequest } from '../utils';

const useTag = () => {
  const UUID = getUUID();
  const prefix = 'tags';

  const createTags = async (data: CreateTagType) => {
    try {
      const response = await trexApi.post(prefix, data, {
        headers: {
          'X-SetID': UUID,
        },
      });

      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      } else {
        toast.warn(`Erro na chamada: POST/${prefix}`);
        throw error;
      }
    }
  };

  const getAllTags = async ({ search, query, scope }: GetAllTagsType) => {
    const response = await handleApiRequest(
      trexApi.get(prefix, {
        params: {
          page: query.page + 1,
          'page-size': query.pageSize,
          'sort-by': query.orderBy?.field
            ? `${query.orderBy.field.toString()},${query.orderDirection}`
            : null,
          search,
          scope,
        },
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  return {
    createTags,
    getAllTags,
  };
};
export default useTag;
