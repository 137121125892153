import React from 'react';
import { GridItem, Stack } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import Breadcrumb from '../../../../components/base/v2/breadcrumb';
import Title from '../../../../components/base/v2/title';
import CommentsForm from '../../../../components/ordersInfo/tabInfo/commentsInfo/components/commentsForm';
import LoadingSpinner from '../../../../components/loadingSpinner';
import CommentsContainer from '../../../../components/ordersInfo/tabInfo/commentsInfo/components/commentsContainer';
import { useRulesFlow } from '../../../../providers/rules_flow';
import useRulesFlows from '../../../../hooks/api/rulesFlows';
import { handleCommentsFormToModel } from '../../utils';

import * as S from '../../styles';
import * as I from '../../../../components/ordersInfo/tabInfo/commentsInfo/interface';
import * as I2 from '../../../../interfaces/order';

const CommentsFlow = (): React.ReactElement => {
  const { dataContext, isLoaded, getNewVersion } = useRulesFlow();
  const { updateFlow } = useRulesFlows();

  const handleSubmit = async (
    data: I.FlowCommentsFormModel | I2.ReviewDetailEvents
  ) => {
    try {
      const res = await updateFlow({
        id: dataContext.id,
        data: handleCommentsFormToModel(
          data as I.FlowCommentsFormModel,
          dataContext
        ),
        version: dataContext.version,
      });
      if ([200, 201, 204].includes(res.request.status)) {
        toast.success('Comentário adicionado com sucesso!');
        getNewVersion();
        return;
      }
      const { reason } = JSON.parse(res.request.response);
      toast.error(
        `Não foi possível adicionar o comentátio so fluxo. ${reason}`
      );
    } catch {
      toast.error('Não foi possível adicionar o comentátio so fluxo.');
    }
  };

  if (!isLoaded) {
    return (
      <S.LoadingContainer>
        <LoadingSpinner />
      </S.LoadingContainer>
    );
  }

  return (
    <S.Container>
      <Stack spacing="medium" py="extra-large">
        <Breadcrumb
          items={[
            { label: 'Início', href: '/' },
            { label: 'Fluxo de Regras', href: '/rules-flow' },
            {
              label: dataContext?.description,
              href: `/rules-flow/info/${dataContext.id}`,
            },
            { label: 'Comentários' },
          ]}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Title showBackButton text="Comentários" />
        </Stack>
      </Stack>

      <S.GridContainer>
        <GridItem colSpan={1}>
          <CommentsContainer
            flow={dataContext?.flow_change_history}
            textEmptyValue="Este Fluxo ainda não teve nenhum comentário, caso necessário, adicione-o no campo de texto ao lado."
          />
        </GridItem>
        <GridItem colSpan={1}>
          <CommentsForm onSubmit={handleSubmit} hasTitle />
        </GridItem>
      </S.GridContainer>
    </S.Container>
  );
};

export default CommentsFlow;
