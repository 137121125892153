import React from 'react';

import * as I from './interfaces';
import * as S from './styles';

export default function Button({
  children,
  onClick,
  height = 'bigger-plus',
  ...props
}: I.ButtonProps): React.ReactElement {
  return (
    <S.StyledButton {...props} onClick={onClick} height={height}>
      {children}
    </S.StyledButton>
  );
}
