/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Checkbox,
  Icon,
  MenuItem,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FaRegClone } from 'react-icons/fa';
import { toast } from 'react-toastify';
import DoubleCheckModal from '../../doubleCheckModal';
import InputText from '../../inputs/text';
import InputSelect from '../../inputs/select';
import LoadingSpinner from '../../../../loadingSpinner';
import useBlocks from '../../../../../hooks/api/blocks';
import useRulesFlows from '../../../../../hooks/api/rulesFlows';
import { useRulesFlow } from '../../../../../providers/rules_flow';
import { handleFormDataToModel } from '../../../../../pages/rulesFlow/utils';

const CloneFlowModal = (): React.ReactElement => {
  const { dataContext, rulesData, isLoaded } = useRulesFlow();
  const [isLoading, setIsLoading] = useState(false);
  const [cloneToBlock, setCloneToBlock] = useState(false);
  const [cloneData, setCloneData] = useState({
    description: '',
    allowReview: false,
  });

  const { createFlow } = useRulesFlows();
  const { createBlock } = useBlocks();
  const { push } = useHistory();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onClone = async (): Promise<void> => {
    setIsLoading(true);
    const data = {
      allow_review: cloneData.allowReview,
      description: cloneData.description,
      nodes: dataContext.nodes,
    };

    try {
      const handleAPIFunction = cloneToBlock ? createBlock : createFlow;
      const handleLink = cloneToBlock ? '/blocks' : '/rules-flow';

      const res = await handleAPIFunction({
        data: handleFormDataToModel(rulesData.rules, data as any),
      });
      if (res && [200, 201, 204].includes(res.request.status)) {
        toast.success('Fluxo clonado com sucesso!');
        push(`${handleLink}/info/${res.data.id}`);
      } else {
        const { reason } = JSON.parse(res.request.response);
        toast.error(`Não foi possível clonar o fluxo: ${reason}`);
      }
    } catch (error) {
      toast.error(
        'Não foi possível clonar o fluxo. Tente novamente mais tarde.'
      );
    } finally {
      onClose();
      setIsLoading(false);
    }
  };

  const handleDescription = (e: React.ChangeEvent<{ value: string }>) => {
    setCloneData({ ...cloneData, description: e.target.value });
  };

  const handleAllowReview = (e: React.ChangeEvent<{ value: string }>) => {
    setCloneData({
      ...cloneData,
      allowReview: e.target.value === 'true',
    });
  };

  if (!isLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <MenuItem icon={<Icon as={FaRegClone} />} onClick={onOpen}>
        Clonar fluxo
      </MenuItem>

      <DoubleCheckModal
        title="Clonar fluxo existente"
        description={`Ao clonar o fluxo ${dataContext?.description}, é importante lembrar que quaisquer alterações não salvas não serão incluídas na cópia.`}
        isOpen={isOpen}
        onClose={onClose}
        modal={{ size: 'xl' }}
        primaryButton={{
          colorScheme: 'green',
          text: 'Clonar fluxo',
          action: onClone,
        }}
        isLoading={isLoading}
      >
        <Stack
          spacing="medium"
          background="white"
          p="medium"
          borderRadius="extra-large"
        >
          <Text fontWeight="bold">Informações do fluxo</Text>
          <Stack direction="row" spacing="medium">
            <InputText
              label="Fluxo"
              value={cloneData.description}
              onChange={handleDescription}
              formcontrol={{}}
            />

            <InputSelect
              label="Permitir revisão"
              options={[
                { option: 'Sim', value: 'true' },
                { option: 'Não', value: 'false' },
              ]}
              value={cloneData.allowReview.toString()}
              onChange={handleAllowReview}
              formcontrol={{}}
            />
          </Stack>
          <Checkbox
            isChecked={cloneToBlock}
            onChange={() => setCloneToBlock(!cloneToBlock)}
            colorScheme="green"
            my={5}
          >
            Clonar o fluxo em forma de bloco
          </Checkbox>
        </Stack>
      </DoubleCheckModal>
    </>
  );
};

export default CloneFlowModal;
