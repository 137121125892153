import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { trexApi, trexApiV2 } from '../../services/api';
import LoadingSpinner from '../../components/loadingSpinner';
import { PageSpinner } from './styles';
import { SessionContextType } from './interface';

const InterceptorContext = createContext<SessionContextType | undefined>(
  undefined
);

export const InterceptorProvider = ({ children }: PropsWithChildren<{}>) => {
  const { keycloak } = useKeycloak();
  const [token, setToken] = useState<string>('');

  const requestInterceptor = async (
    request: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> => {
    return keycloak
      .updateToken(300)
      .then(() => {
        if (request.headers) {
          setToken(keycloak.token as string);
          request.headers.Authorization = `Bearer ${keycloak.token}`;
          request.headers['X-SessionID'] = keycloak.sessionId;
        }
        return keycloak.loadUserProfile();
      })
      .then((response) => {
        if (request.headers) {
          request.headers['X-Email'] = response.email;
        }
        return request;
      });
  };

  const responseInterceptor = async (response: AxiosResponse) => response;

  const errorResponseInterceptor = async (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      if (!keycloak.authenticated) {
        keycloak.logout();
      }
    }
    return Promise.reject(error);
  };

  useEffect(() => {
    if (keycloak) {
      // Requests
      trexApi.interceptors.request.use(requestInterceptor);
      trexApiV2.interceptors.request.use(requestInterceptor);

      // Responses
      trexApi.interceptors.response.use(
        responseInterceptor,
        errorResponseInterceptor
      );
      trexApiV2.interceptors.response.use(
        responseInterceptor,
        errorResponseInterceptor
      );
    }
  }, [keycloak]);

  if (keycloak) {
    return (
      <InterceptorContext.Provider value={{ token }}>
        {children}
      </InterceptorContext.Provider>
    );
  }
  return (
    <PageSpinner>
      <LoadingSpinner />
    </PageSpinner>
  );
};

export const useInterceptor = () => {
  const context = useContext(InterceptorContext);
  if (context === undefined) {
    throw new Error('useInterceptor must be used within a InterceptorProvider');
  }
  return context;
};
