import React from 'react';
import InputText from './text';
import InputNumber from './number';
import InputSelect from './select';
import InputSearchSelect from './searchselect';
import InputDuration from './duration';
import { booleanOption, booleanWithNullOption, getError } from './utils';

import * as I from './interfaces';

const InputFactory: React.FC<I.InputFactoryProps> = ({
  name,
  path,
  type,
  methods,
  fetchByAPI,
  defaultValue = '',
  isDisabled = false,
  isRequired = false,
  multiple = false,
  values = [],
}) => {
  const handleInputType = () => {
    switch (type) {
      case 'text':
        return (
          <InputText
            label={name}
            defaultValue={defaultValue}
            activeLabel
            {...methods.register(`${path}`)}
            formcontrol={{
              isRequired,
              isDisabled,
              error: getError({ errors: methods.formState.errors, path }),
            }}
          />
        );

      case 'select':
        if (multiple && values) {
          return (
            <InputSearchSelect
              isMulti
              control={methods.control}
              label={name}
              name={path}
              formcontrol={{
                isRequired,
                isDisabled,
                error: getError({ errors: methods.formState.errors, path }),
              }}
              initialOptions={values.map((value) => ({ label: value, value }))}
              fetchByAPI={fetchByAPI}
            />
          );
        }

        return (
          <>
            {values && (
              <InputSelect
                label={name}
                defaultValue={defaultValue}
                options={values.map((value) => ({ option: value, value }))}
                {...methods.register(`${path}`)}
                formcontrol={{
                  isRequired,
                  isDisabled,
                  error: getError({ errors: methods.formState.errors, path }),
                }}
              />
            )}
          </>
        );

      case 'autocomplete':
        return (
          <InputSearchSelect
            isMulti
            isClearable
            control={methods.control}
            label={name}
            name={path}
            formcontrol={{
              isRequired,
              isDisabled,
              error: getError({ errors: methods.formState.errors, path }),
            }}
            initialOptions={values?.map((value) => ({ label: value, value }))}
            fetchByAPI={fetchByAPI}
          />
        );

      case 'boolean':
        return (
          <InputSelect
            label={name}
            defaultValue={defaultValue}
            options={isRequired ? booleanWithNullOption : booleanOption}
            {...methods.register(`${path}`)}
            formcontrol={{
              isRequired,
              isDisabled,
              error: getError({ errors: methods.formState.errors, path }),
            }}
          />
        );

      case 'number':
        return (
          <InputNumber
            label={name}
            {...methods.register(`${path}`, {
              valueAsNumber: true,
            })}
            defaultValue={defaultValue}
            formcontrol={{
              isRequired,
              isDisabled,
              error: getError({ errors: methods.formState.errors, path }),
            }}
          />
        );

      case 'float':
        return (
          <InputNumber
            label={name}
            precision={3}
            step={0.001}
            {...methods.register(`${path}`, {
              valueAsNumber: true,
            })}
            defaultValue={defaultValue}
            formcontrol={{
              isRequired,
              isDisabled,
              error: getError({ errors: methods.formState.errors, path }),
            }}
          />
        );

      case 'duration':
        return (
          <InputDuration
            label={name}
            defaultValue={defaultValue}
            activeLabel
            {...methods.register(`${path}`)}
            formcontrol={{
              isRequired,
              isDisabled,
              error: getError({ errors: methods.formState.errors, path }),
            }}
          />
        );

      default:
        return <></>;
    }
  };

  return handleInputType();
};

export default InputFactory;
