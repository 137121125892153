import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import {
  cepMask,
  documentMask,
  phoneMask,
  resetNumbersMask,
} from '../../../utils/mask';
import { fixed, transformClientPercentages } from '../../../utils/percentages';
import * as I from '../../../interfaces/client';
import * as I2 from './interfaces';
import * as I3 from '../../../hooks/api/interfaces';

const handleAddress = (address?: I.AddressModel) => {
  if (
    address &&
    !address.country &&
    !address.city &&
    !address.street &&
    !address.postal_code
  ) {
    return undefined;
  }

  if (address && address.postal_code) {
    address.postal_code = resetNumbersMask(address.postal_code);
  }

  return address;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkValue = (value: string | number | Date | undefined): any => {
  if (value) return value;
  return undefined;
};

export const handleFormToClientsModel = (data: I.ClientModel) => {
  if (data.contacts?.length) {
    data.contacts.forEach((item) => {
      item.phone = item.phone && resetNumbersMask(item.phone);
    });
  }

  return {
    name: data.name,
    document: resetNumbersMask(data.document),
    address: handleAddress(data.address),
    analysis: checkValue(data.analysis),
    approval_target: transformClientPercentages(data.approval_target),
    chargeback_target: transformClientPercentages(data.chargeback_target),
    contacts: data.contacts?.length ? data.contacts : undefined,
    description: checkValue(data.description),
    external_id: checkValue(data.external_id),
    flow_id: checkValue(data.flow_id),
    group_id: checkValue(data.group_id),
    model_id: checkValue(data.model_id),
    website: checkValue(data.website),
  };
};

export const handleClientsModelToForm = (data: I.ClientModel) => {
  if (data.contacts && data.contacts?.length > 0) {
    data.contacts.forEach((item) => {
      item.phone = phoneMask({ value: item.phone });
    });
  }

  if (data.address && data.address.postal_code) {
    data.address.postal_code = cepMask(data.address.postal_code);
  }

  if (data?.approval_target) {
    data.approval_target = Number(
      (data.approval_target * 100).toFixed(
        fixed(data.approval_target, 'multiply')
      )
    );
  }

  if (data?.chargeback_target) {
    data.chargeback_target = Number(
      (data.chargeback_target * 100).toFixed(
        fixed(data.chargeback_target, 'multiply')
      )
    );
  }

  data.partners = data.partners || [];
  data.document = documentMask(data.document);

  return data;
};

export const handleTagsFormToClientModel = ({
  client,
  data,
  type,
}: I2.FormToClientModelType) => {
  if (type === 'add') {
    return {
      name: client.name,
      id: client.id,
      document: resetNumbersMask(client.document),
      tags_to_add: [data.tag_id],
    };
  }

  return {
    name: client.name,
    id: client.id,
    document: resetNumbersMask(client.document),
    tags_to_remove: [data.tag_id],
  };
};

// Increment number with 6 decimal places
export const incrementDecimalNumber = (number: number) => {
  const rounded = Math.round(number * 1e6) / 1e6;
  return rounded === number
    ? (number + 0.000001).toFixed(6)
    : rounded.toFixed(6);
};

export const handleScopeItems = (scopeName: string) => {
  switch (scopeName) {
    case 'flow':
      return {
        message: 'fluxo',
        comparison: 'flow_id',
      };
    case 'model':
      return {
        message: 'modelo',
        comparison: 'model_id',
      };
    case 'retention':
      return {
        message: 'fluxo de retenção',
        comparison: 'retention_flow_id',
      };
    default:
      return {
        message: 'grupo',
        comparison: 'group_id',
      };
  }
};

export const bindScope = async (
  chainData: AxiosResponse<I.ClientModel>,
  scope: string,
  scopeName: 'group' | 'flow' | 'model' | 'retention',
  requestFunc: ({
    id,
    scopeID,
    version,
  }: I3.GenericBondRequestType) => Promise<AxiosResponse<I.ClientModel>>
) => {
  const { data } = chainData;
  const scopeItems = handleScopeItems(scopeName);

  if (
    [200, 201, 204].includes(chainData.request.status) &&
    scope !== data[scopeItems.comparison as keyof I.ClientModel] &&
    scope !== ''
  ) {
    try {
      const res = await requestFunc({
        id: data.id,
        scopeID: scope,
        version: data.version,
      });

      if ([200, 201, 204].includes(res.request.status)) {
        toast.success(
          `O cliente foi adicionado ao ${scopeItems.message} com sucesso!`
        );
        chainData.data.version = incrementDecimalNumber(Number(data.version));
      } else {
        const { reason } = JSON.parse(res.request.response);
        toast.warn(
          `Não foi possível adicionar o cliente ao ${scopeItems.message} ${reason}`
        );
      }
    } catch (error) {
      toast.warn(
        `Não foi possível adicionar o cliente ao ${scopeItems.message}`
      );
    }
  }

  return chainData;
};
