import { Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import InformationBase from './baseInformation';
import InputText from '../../../../components/base/v2/inputs/text';
import InputSelect from '../../../../components/base/v2/inputs/select';

import * as I from '../interfaces';

const Submenu: React.FC<I.SubmenuProps> = ({
  isEditing,
  isBlock,
  isRetention,
  flow,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const label = isBlock ? 'Bloco' : 'Fluxo';
  const width = isRetention ? '100%' : '25%';

  return (
    <>
      {isEditing ? (
        <Stack
          data-testid="flow-submenu-edit"
          spacing="medium"
          background="white"
          p="medium"
          borderRadius="extra-large"
          mb="16px"
        >
          <Text fontWeight="bold">Informações do {label}</Text>
          <Stack direction="row" spacing="medium">
            <InputText
              label={label}
              {...register('description')}
              formcontrol={{
                isDisabled: !isEditing,
                isRequired: true,
                error: errors.description,
                style: { width },
              }}
            />

            {!isRetention && (
              <InputSelect
                label="Permitir revisão"
                options={[
                  { option: 'Sim', value: 'true' },
                  { option: 'Não', value: 'false' },
                ]}
                {...register('allow_review')}
                formcontrol={{
                  isDisabled: !isEditing,
                  isRequired: true,
                  error: errors.description,
                }}
              />
            )}
          </Stack>
        </Stack>
      ) : (
        <InformationBase
          dataTestId="flow-submenu"
          name={label}
          identifierPrefix="flow"
          flow={flow}
          isRetention={isRetention}
        />
      )}
    </>
  );
};

export default Submenu;
