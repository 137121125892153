/* eslint-disable react/require-default-props */
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { GroupModel } from '../../interfaces/groups';
import { trexApi } from '../../services/api';
import { getUUID } from '../../services/storage';

interface ContextModel {
  dataContext: GroupModel | null;
  loadData(): Promise<void>;
  initialized: boolean;
  reloadData(value: string): Promise<void>;
}

interface ProviderProps {
  id?: string;
  children: React.ReactNode;
}

const QueueContext = createContext<ContextModel>({} as ContextModel);

const GroupsProvider = ({
  children,
  id,
}: ProviderProps): React.ReactElement => {
  const UUID = getUUID();
  const [data, setData] = useState<GroupModel | null>(null);
  const [initialized, setInitialized] = useState(false);

  const loadData = useCallback(async (): Promise<void> => {
    const response = await trexApi.get(`groups/${id}`, {
      headers: { 'X-SetID': UUID },
    });

    setData(response.data);
    setInitialized(true);
  }, [id]);

  async function reloadData(value: string): Promise<void> {
    const response = await trexApi.get(`groups/${value}`, {
      headers: { 'X-SetID': UUID },
    });

    setData(response.data);
    setInitialized(true);
  }

  useEffect(() => {
    if (id) {
      loadData();
    }

    if (!id) {
      setInitialized(true);
    }
  }, [id]);

  return (
    <QueueContext.Provider
      value={{
        dataContext: data,
        loadData,
        initialized,
        reloadData,
      }}
    >
      {children}
    </QueueContext.Provider>
  );
};

function useGroups(): ContextModel {
  const context = useContext(QueueContext);
  const { dataContext, loadData, initialized, reloadData } = context;
  return { dataContext, loadData, initialized, reloadData };
}

export { GroupsProvider, useGroups };
