import { Avatar } from '@chakra-ui/react';
import React from 'react';
import Stat from '../../../../stat';
import ItemRow from './ItemRow';
import { checkDateCustom } from '../../../../../utils/check_values';
import { handleTreeObjects } from '../../../../boxReview/utils';

import * as S from '../styles';
import * as I from '../interface';
import * as I2 from '../../../../boxReview/interface';

const CommentItem = ({
  commentInfo,
  suspensionInfo,
  flowInfo,
  backgroundColor,
  identifier,
  event,
}: I.CommentProps): React.ReactElement => {
  const handleHintTreeItems = () => {
    if (commentInfo?.hint_tree) {
      const selectedItems = handleTreeObjects(commentInfo.hint_tree);
      const ObjEntries: [string, I2.SelectOptionsType][] =
        Object.entries(selectedItems);
      return ObjEntries.map(([key, value]) => {
        return <ItemRow key={key} data={value} />;
      });
    }

    return '';
  };

  const renderInfo = (label: string, value: string) =>
    value && (
      <S.Row>
        <S.ItemText>
          <b>{label}: </b>
          {value}
        </S.ItemText>
      </S.Row>
    );

  return (
    <S.Container data-testid={`comment-item-${identifier}`}>
      <S.Row>
        <Avatar mr="8px" />
        {(commentInfo || suspensionInfo || flowInfo) && (
          <Stat
            text={
              commentInfo?.created_by ||
              suspensionInfo?.created_by ||
              flowInfo?.author
            }
            subtitle={checkDateCustom(
              commentInfo?.created_at ||
                suspensionInfo?.created_at ||
                flowInfo?.created_at
            )}
          />
        )}
      </S.Row>
      <S.Row>
        <S.CommentBox $backgroundColor={backgroundColor}>
          {handleHintTreeItems()}
          {commentInfo?.message && (
            <>{renderInfo('Comentário', commentInfo.message)}</>
          )}
          {flowInfo && (
            <>
              {renderInfo('Título', flowInfo.title)}
              {renderInfo('Comentário', flowInfo.description)}
            </>
          )}

          {event && <>{renderInfo('Evento', event)}</>}
        </S.CommentBox>
      </S.Row>
    </S.Container>
  );
};

export default CommentItem;
