import React, {
  useContext,
  createContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import useModels from '../../hooks/api/models';

import * as I from './interfaces';
import * as I2 from '../../interfaces/models';

const RulesModelsContext = createContext<I.Context>({} as I.Context);

const RulesModelsProvider = ({
  children,
}: I.ProviderProps): React.ReactElement => {
  const { getOneModel, getAllFeatures } = useModels();
  const [modelsData, setModelsData] = useState<I2.ModelsModel>();
  const [featuresData, setFeaturesData] = useState<I2.FeaturesModel[]>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isModelsLoaded, setIsModelsLoaded] = useState(false);
  const [isFeaturesLoaded, setIsFeaturesLoaded] = useState(false);

  const { id } = useParams<{ id: string }>();

  const handleGetModels = async (): Promise<void> => {
    setIsModelsLoaded(false);
    if (id) {
      const response = await getOneModel(id);
      setModelsData(response.data);
    }
    setIsModelsLoaded(true);
  };

  const handleGetRules = async (): Promise<void> => {
    setIsFeaturesLoaded(false);
    const response = await getAllFeatures();
    setFeaturesData(response.data.items);
    setIsFeaturesLoaded(true);
  };

  const handleDisabled = async (): Promise<void> => {
    setIsDisabled((old) => !old);
  };

  useEffect(() => {
    let cleanUp = true;
    if (cleanUp) {
      handleGetModels();
      handleGetRules();
    }
    return () => {
      cleanUp = false;
    };
  }, []);

  useEffect(() => {
    setIsLoaded(Boolean(isModelsLoaded && isFeaturesLoaded));
  }, [isModelsLoaded, isFeaturesLoaded]);

  return (
    <RulesModelsContext.Provider
      value={useMemo(
        () => ({
          dataContext: modelsData || {
            description: '',
            external_uri: '',
            backend: '',
            features: [{ name: '' }],
          },
          isLoaded,
          features: featuresData || [],
          featuresNames: featuresData?.map((feature) => feature.name) || [],
          hasID: id,
          handleDisabled,
          getNewVersion: handleGetModels,
          isDisabled,
        }),
        [isLoaded, isDisabled]
      )}
    >
      {children}
    </RulesModelsContext.Provider>
  );
};

function useRulesModels(): I.Context {
  const context = useContext(RulesModelsContext);
  const {
    dataContext,
    isLoaded,
    isDisabled,
    features,
    featuresNames,
    hasID,
    handleDisabled,
    getNewVersion,
  } = context;
  return {
    dataContext,
    isLoaded,
    isDisabled,
    features,
    featuresNames,
    hasID,
    handleDisabled,
    getNewVersion,
  };
}

export { RulesModelsProvider, useRulesModels };
