import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useAtom } from 'jotai';
import GenericForm from '../../../components/base/v2/genericForm';
import FlowUpsertForm from '../../../sections/flow/form';
import useBlocks from '../../../hooks/api/blocks';
import { emptyFlowObject } from '../../../providers/rules_flow/utils';
import { handleFormDataToModel } from '../../rulesFlow/utils';
import { constants } from '../../../constants';
import { isEditingFlow } from '../../../sections/flow/form/store';
import { formatConstants } from '../../../utils/formatConstants';
import { useRulesFlow } from '../../../providers/rules_flow';

import * as I from '../../../interfaces/flow';

const BlocksCreate = () => {
  const [, setIsEditing] = useAtom(isEditingFlow);

  const { rulesData } = useRulesFlow();
  const { createBlock } = useBlocks();
  const { push } = useHistory();

  const handleSubmit = async (data: I.FluxModelForm) => {
    try {
      const res = await createBlock({
        data: handleFormDataToModel(rulesData.rules, data),
      });
      if ([200, 201, 204].includes(res.request.status)) {
        const message = formatConstants(
          constants.publishedWithSuccess,
          'Bloco'
        );
        toast.success(message);
        push(`/blocks/info/${res.data.id}`);
        return;
      }
      const { reason } = JSON.parse(res.request.response);
      toast.error(`${constants.errorToSubmitWithReason}: ${reason}`);
    } catch {
      toast.error(constants.errorToSubmitContent);
    }
  };

  useEffect(() => {
    setIsEditing(true);
  });

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Blocos', href: '/blocks' },
        { label: 'Cadastro' },
      ]}
      title="Cadastro de Blocos"
      backButtonUrl="/blocks"
      showBackButton
    >
      <FlowUpsertForm
        flow={emptyFlowObject}
        handleSubmit={handleSubmit}
        isLoaded
        isBlock
      />
    </GenericForm>
  );
};

export default BlocksCreate;
