import React from 'react';
import { GridItem } from '@chakra-ui/react';
import Stat from '../../../stat';
import Badge from '../../../badge';
import { checkNumber, checkString } from '../../../../utils/check_values';
import { getStatusDetails } from '../../../../utils/handle_status';
import { handleInstallmentValue } from './utils';

import * as S from './styles';
import * as I from './interfaces';

const CreditCardInfo = ({
  payment,
}: I.CreditCardInfoProps): React.ReactElement => {
  const { label, background, color, icon } = getStatusDetails({
    status: payment.status,
    isPaymentStatus: true,
  });

  return (
    <S.CreditCardInfo>
      <GridItem colSpan={2}>
        <Stat
          title="Valor"
          text={checkString(payment['amount@display'])}
          identifier="amount_display"
          copy={checkString(payment['amount@display'])}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <Stat
          title="Parcelas"
          text={checkNumber(payment.installments)}
          identifier="installments"
          copy={checkNumber(payment.installments)}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <Stat
          title="Valor estimado da parcela:"
          text={handleInstallmentValue(
            payment['amount@display'],
            payment.installments
          )}
          identifier="installments_value"
          copy={handleInstallmentValue(
            payment['amount@display'],
            payment.installments
          )}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <Stat
          title="Emissor"
          text={checkString(payment.issuer)}
          identifier="name"
          copy={checkString(payment.issuer)}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <Stat
          title="Processador"
          text={checkString(payment.processor)}
          identifier="processor"
          copy={checkString(payment.processor)}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <Stat title="Status" identifier="name">
          <Badge
            label={label}
            color={color}
            background={background}
            fontSize="small"
            icon={icon}
          />
        </Stat>
      </GridItem>
    </S.CreditCardInfo>
  );
};

export default CreditCardInfo;
