import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { useAtom } from 'jotai';
import GenericForm from '../../../components/base/v2/genericForm';
import FlowUpsertForm from '../../../sections/flow/form';
import { isEditingFlow, ResetAtom } from '../../../sections/flow/form/store';
import { constants } from '../../../constants';
import { useRetentionFlow } from '../../../hooks/api/retentionFlow';
import { emptyRetentionFlow, handleFormDataToRetentionModel } from '../utils';
import { formatConstants } from '../../../utils/formatConstants';

import { RetentionModel } from '../../../interfaces/retentionFlow';
import { FluxModelForm } from '../../../interfaces/flow';

const RetentionFlowInfo = () => {
  const [isEditing, setIsEditing] = useAtom(isEditingFlow);
  const { resetIsEditingFlow, resetIsEditingNodeAtom } = ResetAtom();

  const [initialValues, setInitialValues] =
    useState<RetentionModel>(emptyRetentionFlow);
  const [isLoading, setIsLoading] = useState(true);
  const { push } = useHistory();

  const { updateRetentionFlow, getRetentionFlowById } = useRetentionFlow();
  const { id } = useParams<{ id: string }>();

  const handleDirectToList = () => {
    push(`/retention-flow/info/clients/${id}`);
  };

  const handleEditing = () => {
    setIsEditing(!isEditing);
  };
  const loadInfo = async () => {
    try {
      setIsLoading(true);
      const { data } = await getRetentionFlowById(id);
      setInitialValues(data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (data: FluxModelForm | RetentionModel) => {
    try {
      const res = await updateRetentionFlow({
        data: handleFormDataToRetentionModel(data as RetentionModel),
        id,
        version: initialValues.version,
      });
      if ([200, 201, 204].includes(res.request.status)) {
        handleEditing();
        const message = formatConstants(
          constants.updatedWithSuccess,
          'Fluxo de retenção'
        );
        toast.success(message);
        loadInfo();
        return;
      }

      const { reason } = JSON.parse(res.request.response);
      toast.error(`${constants.errorToSubmitWithReason}: ${reason}`);
    } catch {
      toast.error(constants.errorToSubmitContent);
    }
  };

  useEffect(() => {
    loadInfo();
    resetIsEditingFlow();
    resetIsEditingNodeAtom();
  }, []);

  return (
    <GenericForm
      breadcrumb={[
        { label: 'Início', href: '/' },
        { label: 'Fluxo de Retenção', href: '/retention-flow' },
        { label: initialValues?.description },
      ]}
      secondaryActions={[
        {
          text: 'Clientes',
          action: handleDirectToList,
        },
      ]}
      title={initialValues?.description}
      isDisabled={!isEditing}
      handleAction={handleEditing}
      actionText="Editar Fluxo"
      backButtonUrl="/retention-flow"
      copyText={initialValues?.id}
      showBackButton
    >
      <FlowUpsertForm
        flow={initialValues}
        isLoaded={!isLoading}
        handleSubmit={handleSubmit}
        isRetention
      />
    </GenericForm>
  );
};

export default RetentionFlowInfo;
