/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  AccordionItem,
  AccordionIcon,
  Button,
  Text,
  Input,
  useDisclosure,
  Flex,
  Icon,
  Stack,
} from '@chakra-ui/react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormProvider, useForm } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { useKeycloak } from '@react-keycloak/web';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { RxPinLeft, RxPinRight } from 'react-icons/rx';

import { useOrder } from '../../providers/order';
import useAnalysis from '../../hooks/api/analysis';
import useNavegate from '../../hooks/navegate';
import { trexApi } from '../../services/api';
import { getUUID } from '../../services/storage';

import ReviewModal from '../reviewModal';
import {
  handleDataToForm,
  handleFormToReviewModel,
  handleSearchInputOptions,
  handleStatus,
  handleSummary,
  handleTreeObjects,
  reviewSelectStatus,
  reviewSelectStatusValues,
  validationSchema,
} from './utils';
import useHintTree from '../../hooks/api/hintTree';

import * as S from './styles';
import * as I from './interface';

export const BoxReview = () => {
  const UUID = getUUID();
  const [show, setShow] = useState(false);
  const [maxDate, setMaxDate] = useState(Number(new Date()));
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [boxReviewPositionX, setBoxReviewPositionX] = useState('right');
  const [formData, setFormData] = useState<any>();
  const [hintTreeOptions, setHintTreeOptions] =
    useState<Record<string, I.SelectOptionsType>>();
  const { getAllOptions } = useHintTree();

  const { keycloak } = useKeycloak();
  const { orderContext, loadOrder } = useOrder();
  const { updateOrderReviewStatus } = useAnalysis();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    register,
    formState,
    watch,
    handleSubmit,
    getValues,
    setValue,
    reset,
    control,
  } = methods;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { BackPage } = useNavegate();
  const { push } = useHistory();

  const user =
    JSON.parse(localStorage.getItem('user') ?? 'false') ||
    keycloak.profile?.username;

  const handleHintTree = async () => {
    const { data } = await getAllOptions();
    setHintTreeOptions(handleTreeObjects(data));
  };

  const handleDefaultValues = () => {
    if (orderContext?.review_detail?.events) {
      const event = orderContext.review_detail.events
        .filter((item: any) => item.action === 'delay')
        .pop();

      if (event?.hint_tree) {
        const dataToForm = handleDataToForm(event);

        Object.entries(dataToForm).forEach((item) => {
          setValue(item[0], item[1]);
        });
      }
    }
  };

  useEffect(() => {
    if (orderContext?.review_detail) {
      setMaxDate(
        Number(new Date()) + orderContext.review_detail.seconds_until_sla * 1000
      );
    }

    handleDefaultValues();

    if (
      orderContext?.status === 'pending' ||
      orderContext?.status === 'ready'
    ) {
      handleHintTree().catch(() => {
        toast.warn('Não foi possível pegar os campos da árvore de finalização');
      });

      setShow(true);

      if (
        orderContext.review_detail?.state === 'delayed' ||
        orderContext?.suspension_detail?.state === 'suspended'
      ) {
        setShow(false);
      }

      if (
        orderContext.review_detail?.state === 'in-review' &&
        orderContext.review_detail?.reviewer &&
        user.email !== orderContext.review_detail.reviewer
      ) {
        setShow(false);
      }
    }
  }, [orderContext]);

  const formatDate = `${format(new Date(maxDate), 'yyyy-MM-dd')}T${format(
    new Date(maxDate),
    'HH:mm'
  )}`;

  const startReview = async (e: React.ChangeEvent): Promise<void> => {
    e.preventDefault();
    setShow(false);
    const data = {
      event_type: 'review',
      action: 'begin',
    };

    try {
      await updateOrderReviewStatus({
        id: orderContext?.id,
        data,
        version: orderContext?.version,
      }).then((result) => {
        if ([200, 201].includes(result.status)) {
          return toast.success('Revisão iniciada');
        }
        throw Error;
      });
    } catch (error) {
      toast.warn(`Não foi possível iniciar a revisão`);
    } finally {
      await loadOrder();
    }
  };

  const nextQueueNewLayout = async (): Promise<void> => {
    try {
      const res = await trexApi.get(
        `queues/analysis/next?last-analysis=${orderContext?.id}`,
        {
          headers: {
            'X-SetID': UUID,
          },
        }
      );

      if (res.status === 204) {
        toast.warn('Não existem pedidos na fila pessoal, avalie a fila geral');
        BackPage();
      }

      if ([200, 201].includes(res.request.status)) {
        push({
          pathname: `/orders/info/${res.data.id}`,
          state: {
            from: '/filas',
          },
        });
        reset();
        window.location.reload();
        return;
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        toast.warn(
          'Ops! Algum analista já pegou esse pedido antes, buscando por outro pedido'
        );
        await nextQueueNewLayout();
      } else {
        BackPage();
        toast.error(`Não foi possivel iniciar a análise`);
      }
    }
  };

  const onSubmitNewLayout = async (): Promise<void> => {
    setIsSubmiting(true);
    const values = getValues();
    const paramData = handleFormToReviewModel(values);

    if (watch('recommendation') === 'delay') {
      delete paramData.recommendation;
      paramData.action = 'delay';
      paramData.until = new Date(paramData.until!);
    } else {
      delete paramData.until;
      paramData.action = 'finalize';
    }

    try {
      const res: any = await updateOrderReviewStatus({
        id: orderContext?.id,
        data: paramData,
        version: orderContext?.version,
      });

      setIsSubmiting(false);
      if ([200, 201].includes(res.request.status)) {
        toast.success('Revisão concluida com sucesso!');
        push('/filas');
        return;
      }
      const { reason } = JSON.parse(res.request.response);
      toast.warn(`Não foi possível concluir a revisão: ${reason}`);
    } catch (error) {
      setIsSubmiting(false);
      toast.warn(`Não foi possível concluir a revisão`);
      await loadOrder();
    }
  };

  const handleNextQueueNewLayout = async (): Promise<void> => {
    setIsSubmiting(true);
    const values = getValues();
    const defaultData = handleFormToReviewModel(values);
    defaultData.event_type = 'review';

    if (watch('recommendation') === 'delay') {
      delete defaultData.recommendation;
      defaultData.action = 'delay';
      defaultData.until = new Date(defaultData.until!);
    } else {
      delete defaultData.until;
      defaultData.action = 'finalize';
    }

    try {
      const res: any = await updateOrderReviewStatus({
        id: orderContext?.id,
        data: defaultData,
        version: orderContext?.version,
      });

      if ([200, 201].includes(res.request.status)) {
        toast.success('Revisão concluida com sucesso!');
        await nextQueueNewLayout();
        return;
      }
      const { reason } = JSON.parse(res.request.response);
      setIsSubmiting(false);
      toast.warn(`Não foi possível concluir a revisão: ${reason}`);
      await loadOrder();
    } catch (error) {
      setIsSubmiting(false);
      toast.warn(`Não foi possível concluir a revisão`);
      await loadOrder();
    }
  };

  const handleNextQueueAction = useCallback(() => {
    (async () => {
      try {
        await nextQueueNewLayout();
      } catch (error) {
        toast.error('Erro ao ir para o próximo pedido');
      }
    })();
  }, [nextQueueNewLayout]);

  const handleAction = useCallback(() => {
    onClose();
    (async () => {
      try {
        await handleNextQueueNewLayout();
      } catch (error) {
        toast.error('Erro ao lidar com a ação de próximo pedido');
      }
    })();
  }, [onClose, handleNextQueueNewLayout]);

  const handleSecondAction = useCallback(() => {
    onClose();
    (async () => {
      try {
        await onSubmitNewLayout();
      } catch (error) {
        toast.error('Erro ao lidar com a ação de enviar');
      }
    })();
  }, [onClose, onSubmitNewLayout]);

  const handleFormSubmit = (data: any) => {
    setFormData(data);
    onOpen();
  };

  const handleFormSubmitAction = useCallback(
    (e) => {
      e.preventDefault();
      (async () => {
        try {
          await handleSubmit(handleFormSubmit)(e);
        } catch (error) {
          toast.error('Erro ao lidar com a ação de submissão do formulário');
        }
      })();
    },
    [handleSubmit, handleFormSubmit]
  );

  if (!show) {
    return null;
  }

  const handleMoveBoxReview = () => {
    if (boxReviewPositionX === 'right') {
      setBoxReviewPositionX('left');
    } else {
      setBoxReviewPositionX('right');
    }
  };

  return (
    <S.Container
      allowMultiple
      defaultIndex={[0]}
      data-testid="boxReview-container"
      positionX={boxReviewPositionX}
      maxHeight="600px"
    >
      <AccordionItem>
        <S.Row>
          {orderContext?.review_detail?.state === 'in-review' &&
          user?.email === orderContext?.review_detail?.reviewer ? (
            <Flex
              direction="row"
              borderBottom="1px solid"
              borderColor="v2.dark.100"
              alignItems="center"
              width="100%"
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing="smallest"
                flex="1"
                data-testid="review-started-title"
              >
                <Button
                  variant="ghost"
                  type="button"
                  onClick={handleMoveBoxReview}
                >
                  <Icon
                    as={boxReviewPositionX === 'right' ? RxPinLeft : RxPinRight}
                  />
                </Button>

                <S.TitleText>Revisão Iniciada</S.TitleText>
              </Stack>
              <Stack direction="row" alignItems="center">
                <S.StyledButton>
                  <AccordionIcon />
                </S.StyledButton>
              </Stack>
            </Flex>
          ) : (
            <S.ButtonsWrapper>
              <Button
                variant="ghost"
                onClick={handleNextQueueAction}
                data-testid="next-queue"
              >
                <Text>Ir para a próxima</Text>
              </Button>
              <S.AnalysisButton
                width="132px"
                id="btn-start-analysis"
                onClick={startReview}
                data-testid="start-analysis-button"
              >
                <Text>Iniciar Análise</Text>
              </S.AnalysisButton>
            </S.ButtonsWrapper>
          )}
        </S.Row>
        <S.FormContainer>
          {hintTreeOptions && (
            <FormProvider {...methods}>
              <S.StyledForm onSubmit={handleFormSubmitAction}>
                <S.InputContainer>
                  {Object.entries(hintTreeOptions).map((item, index) => {
                    const key = `seachInput-${index}`;
                    return (
                      <S.StyledSeachInput
                        key={key}
                        control={control}
                        name={item[0]}
                        label={item[1].name}
                        initialOptions={handleSearchInputOptions(
                          item[1]?.nodes
                        )}
                        formcontrol={{
                          error: formState.errors?.[item[0]],
                        }}
                        isMulti
                        dataTestId={`${item[0]}-select`}
                        marginTop="6px"
                      />
                    );
                  })}

                  <S.TextArea
                    {...register('message')}
                    rows={5}
                    placeholder="Comentário"
                    disabled={isSubmiting || formState.isSubmitting}
                  />
                  {watch('recommendation') === 'delay' && (
                    <S.DatePickerContainer>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Input
                          {...register('until')}
                          name="until"
                          label="Data de agendamento"
                          type="datetime-local"
                          format="dd/MM/yyyy HH:mm"
                          max={formatDate}
                          maxDateMessage="A data não pode passar a SLA"
                        />
                      </MuiPickersUtilsProvider>
                    </S.DatePickerContainer>
                  )}
                </S.InputContainer>

                <S.ButtonsContainer
                  display="flex"
                  direction="row"
                  justify="space-between"
                  positionX={boxReviewPositionX}
                >
                  <S.StyledSelect
                    {...register('recommendation')}
                    placeholder="Definir finalização"
                    name="recommendation"
                    path="recommendation"
                    options={reviewSelectStatus}
                    values={reviewSelectStatusValues}
                    isDisabled={isSubmiting || formState.isSubmitting}
                    error={formState.errors?.recommendation}
                  />
                  <S.AnalysisButton
                    type="submit"
                    isLoading={isSubmiting || formState.isSubmitting}
                  >
                    Salvar análise
                  </S.AnalysisButton>
                </S.ButtonsContainer>
              </S.StyledForm>
            </FormProvider>
          )}
        </S.FormContainer>
      </AccordionItem>
      <ReviewModal
        title={`${handleStatus(formData?.recommendation ?? '')} pedido`}
        description={`Você tem certeza que quer ${handleStatus(
          formData?.recommendation ?? ''
        )} o pedido? Este processo não poderá ser desfeito.`}
        action={handleAction}
        secondAction={handleSecondAction}
        closeText="Enviar"
        isOpen={isOpen}
        onClose={onClose}
        actionText="Sim, Enviar e ir para o próximo"
      >
        <>
          <S.NodeText mt={4} mb={3}>
            Resumo da análise:
          </S.NodeText>
          <S.ReviewSummary>
            {handleSummary(formData, hintTreeOptions)}
          </S.ReviewSummary>
        </>
      </ReviewModal>
    </S.Container>
  );
};

export default BoxReview;
