import { ApexOptions } from 'apexcharts';
import { theme } from '../../../styles/theme';
import { MetricsModel } from '../interfaces';
import { TypeMapModel } from './interfaces';

export const options = (
  metrics: MetricsModel,
  hasFill = false
): ApexOptions => {
  const baseOption: ApexOptions = {
    colors: [theme.colors.v2.green[200]],
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      foreColor: theme.colors.v2.dark[200],
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        color: theme.colors.v2.dark[200],
      },
      axisTicks: {
        color: theme.colors.v2.dark[200],
      },
      categories: metrics?.labels,
    },
  };

  if (hasFill) {
    baseOption.fill = {
      opacity: 0.3,
      type: 'gradient',
      gradient: {
        shade: 'dark',
        opacityFrom: 0.5,
        opacityTo: 0.1,
      },
    };
  }

  return baseOption;
};

export const series = (metrics: MetricsModel, type?: string) => {
  const typeMap: TypeMapModel = {
    Aprovados: {
      name: 'Aprovados',
      data: metrics?.approvalRate,
    },
    Chargeback: {
      name: 'Chargeback',
      data: metrics?.chargebackRate,
    },
    Finalizados: {
      name: 'Finalizados',
      data: metrics?.finishedTransactions,
    },
  };

  const selectedType = (type && typeMap[type]) || {
    name: 'Tempo médio',
    data: metrics?.averageAnalysisTime,
  };

  return [selectedType];
};
