import * as I from './interfaces';

import { trexApi } from '../../../services/api';
import { getUUID } from '../../../services/storage';
import checkValid from '../../../utils/check_valid';
import { handleApiRequest } from '../utils';

const useOrders = () => {
  const UUID = getUUID();
  const prefix = 'analysis';

  const getFilteredOrders = async ({
    page,
    pageSize,
    orderBy,
    orderDirection,
    search,
    filters,
    params,
  }: I.OrdersAPIsQueryType & I.FilterParamsType) => {
    const response = await handleApiRequest(
      trexApi.get(prefix, {
        params: {
          page: page + 1,
          'page-size': pageSize,
          'sort-by': orderBy?.field
            ? `${orderBy.field.toString()},${orderDirection}`
            : null,
          search: checkValid(search),
          ...params,
          ...filters,
        },
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  const getOrdersInfo = async ({ state }: I.OrdersInfoProp) => {
    const response = await handleApiRequest(
      trexApi.get(prefix, {
        params: {
          state,
        },
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  const getQueuesOrders = async ({
    page,
    pageSize,
    orderBy,
    orderDirection,
    search,
  }: I.OrdersAPIsQueryType) => {
    const response = await handleApiRequest(
      trexApi.get(`queues/${prefix}`, {
        params: {
          page: page + 1,
          'page-size': pageSize,
          'sort-by': orderBy?.field
            ? `${orderBy.field.toString()},${orderDirection}`
            : null,
          search: checkValid(search),
        },
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  const getOneOrder = async ({ id }: Pick<I.OrdersAPIsProps, 'id'>) => {
    const response = await handleApiRequest(
      trexApi.get(`${prefix}/${id}`, {
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  const getNextQueue = async () => {
    const response = await handleApiRequest(
      trexApi.get(`queues/${prefix}/next`, {
        headers: {
          'X-SetID': UUID,
        },
      })
    );

    return response;
  };

  return {
    getOneOrder,
    getFilteredOrders,
    getQueuesOrders,
    getNextQueue,
    getOrdersInfo,
  };
};
export default useOrders;
