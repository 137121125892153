import React from 'react';
import { Wrap, WrapItem } from '@chakra-ui/react';
import Stat from '../../../../../stat';
import Badge from '../../../../../badge';
import {
  actionColors,
  actionTranslations,
} from '../../../../../../sections/flow/form/utils';

import * as I from '../../../../../../sections/flow/form/interfaces';

const Information = ({
  node,
  nodeIndex,
}: I.InformationNodeProps): React.ReactElement => {
  const {
    title,
    operator,
    action,
    error_policy: errorPolicy,
    relay_to: relayTo,
  } = node;
  const actionKey = action ?? 'default';
  const translatedOperator = operator === 'OR' ? 'OU' : 'E';
  const translatedErrorPolicy = errorPolicy === 'fail' ? 'Falhar' : 'Ignorar';
  const translatedAction = actionTranslations[actionKey] || 'Delegar';
  const actionColor = actionColors[actionKey] || 'gray';

  return (
    <Wrap spacing="large">
      <WrapItem minW="320px">
        <Stat
          title="Nó"
          text={title}
          identifier={`flow_nodes_${nodeIndex}_title`}
        />
      </WrapItem>
      <WrapItem>
        <Stat
          title="Tipo"
          text={translatedOperator}
          identifier={`flow_nodes_${nodeIndex}_operator`}
        />
      </WrapItem>

      <WrapItem>
        <Stat
          title="Política de erro"
          text={translatedErrorPolicy}
          identifier={`flow_nodes_${nodeIndex}_error_policy`}
        />
      </WrapItem>
      <WrapItem>
        <Stat title="Ação" identifier={`flow_nodes_${nodeIndex}_action`}>
          <Badge label={translatedAction} schema={actionColor} />
        </Stat>
      </WrapItem>

      {translatedAction === 'Delegar' && (
        <WrapItem>
          <Stat
            title="Bloco"
            text={relayTo}
            identifier={`flow_nodes_${nodeIndex}_relay_to`}
          />
        </WrapItem>
      )}
    </Wrap>
  );
};

export default Information;
